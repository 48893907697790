import { Component, OnInit, Input } from '@angular/core';
import {RbdModel} from '../../../../modelos/rbd.model';
import {PortalModel} from '../../../../modelos/portal.model';
import {TrackingService} from '../../../../servicios/tracking.service';
import {DocumentosService} from '../../../../servicios/documentos.service';
import {forkJoin} from 'rxjs';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-resultados-categoria-basica',
  templateUrl: './resultados-categoria-basica.component.html',
  styleUrls: ['./resultados-categoria-basica.component.css']
})
export class ResultadosCategoriaBasicaComponent implements OnInit {

  @Input() public rbdModel: RbdModel;
  @Input() public perfil: number;

  public urlIndicador: string;
  public portalModel: PortalModel;

  url2016: SafeResourceUrl;
  url2017: SafeResourceUrl;
  url2018: SafeResourceUrl;
  url2019;

  constructor(public tracking: TrackingService,
              private domSanitizer: DomSanitizer,
              private route: ActivatedRoute,
              private documentosService: DocumentosService) { }

  ngOnInit() {
    this.portalModel = this.tracking.obtenerNombrePorta(this.perfil, 'basica');
    const subscribes = [];
    subscribes.push(this.documentosService.obtenerUrlDocByNombre('ElabIndDisNivAp'));
    this.route.params.subscribe((pathParams) => {
      if (pathParams.rbd) {
        subscribes.push(this.documentosService.obtenerUrls(pathParams.rbd, 'Basica', this.obtenerPerfil(this.perfil)));

      }
    });
    forkJoin(subscribes).subscribe(
      (result) => {
        this.urlIndicador = result[0].url;
        /*this.url2016 = result[2].a2016 && this.domSanitizer.bypassSecurityTrustResourceUrl(result[2].a2016) || null;
        this.url2017 = result[2].a2017 && this.domSanitizer.bypassSecurityTrustResourceUrl(result[2].a2017) || null;
        this.url2018 = result[2].a2018 && this.domSanitizer.bypassSecurityTrustResourceUrl(result[2].a2018) || null;
        this.url2019 = result[2].a2019;*/
        this.url2016 = result[1].a2016 || null;
        this.url2017 = result[1].a2017 || null;
        this.url2018 = result[1].a2018 || null;
        this.url2019 = result[1].a2019 || null;
      });
   /* subscribes.push(this.documentosService.obtenerUrlDocByNombre('MetConstruccionIDPS-dic-2016'));

    forkJoin(subscribes).subscribe(
      (result) => {
        this.urlIndicador = result[0].url;
      });*/
  }

  public obtenerPerfil(perfil: number): string {
    let nombrePerfil: string;
    switch (perfil) {
      case 1:
        nombrePerfil = 'Director';
        break;
      case 2:
        nombrePerfil = 'sostenedor';
        break;
      case 3:
        nombrePerfil = 'Docente';
        break;
      case 4:
        nombrePerfil = 'Apoderado';
        break;
      case 5:
        nombrePerfil = 'Estudiante';
        break;
      case 6:
        nombrePerfil = 'Agencia';
        break;
      case 7:
        nombrePerfil = 'Otro';
        break;
    }
    return nombrePerfil;
  }

}
