import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EncuestaService {

  public headers = {'Content-Type': 'application/x-www-form-urlencoded'};
  public urlSrv = `${environment.API_DOMAINS}/encuesta/registrar`;

  constructor(private http: HttpClient) { }

  registrar(encuesta: any): Observable<any> {
    const body = JSON.stringify({
      opcion: encuesta.opcion,
      perfil: encuesta.perfil,
      respuestas: encuesta.respuestas
    });

    const headers = this.headers;
    return this.http.post<any>(this.urlSrv, body, {headers}).pipe(
      map((result: any) => {
        console.log(result);
        return result;
      }));
  }
  async registrarAsync(encuesta: any): Promise<any> {
    const body = JSON.stringify({
      opcion: encuesta.opcion,
      respuestas: encuesta.respuestas
    });
    const headers = this.headers;
    return new Promise((resolve, reject) => {
      this.http.post<any>(this.urlSrv, body, {headers}).toPromise();
    });
  }
}
