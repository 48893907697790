import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


declare const Keycloak: any;

@Injectable({
  providedIn: 'root'
})
export class KeycloakService {
  private keycloakAuth: any;

  constructor() { }

  public init(): Promise<any> {
    return new Promise((resolve, reject) => {
      const config = {
        url: environment.sso.url,
        realm: environment.sso.realm,
        clientId: environment.sso.clientId,
        credentials: environment.sso.credentials,
        'ssl-required': 'external',
        'confidential-port': 0
      };
      const initOptions = {
        responseMode: 'fragment',
        flow: 'standard',
        onLoad: 'login-required',
        checkLoginIframe: false
      };
      this.keycloakAuth = new Keycloak(config);
      console.log('this.keycloakAuth 1', this.keycloakAuth);
      this.keycloakAuth.init(initOptions)
        .success(() => {
          resolve();
        })
        .error(() => {
          reject();
        });
    });
  }

  getToken(): string {
    return this.keycloakAuth.token;
  }
  getIdToken(): string {
    return this.keycloakAuth.idToken;
  }

  getScope(): string {
    return this.keycloakAuth.tokenParsed.scope;
  }

  async setUser() {
    console.log('keycloack: setUser');
    const userInfo = await this.loadUserInfo();
    console.log('userInfo', userInfo);
    const atrb = this.obtenerAtributosUsr(userInfo);

    const userData: any = {
      accessToken: this.getToken(),
      apellidoMaterno: '',
      apellidoPaterno: userInfo.lastName ? userInfo.lastName : '',
      director: atrb.directorRBD,
      email: '',
      idToken: this.getIdToken(),
      nombre: userInfo.firstName,
      rut: userInfo.username,
      sostenedor: atrb.autoridadRBD,
      mineduc: atrb.mineducRBD,
      perfil: atrb.perfil,
      rol: userInfo.attributes.rol ? userInfo.attributes.rol : '',
      sisUser: userInfo.attributes.sisUser ? userInfo.attributes.sisUser : '',
      scope: this.getScope(),
      selectProfile : userInfo.attributes.selectProfile[0],
      portal: atrb.portal
    };

    console.log('userData', userData);
    localStorage.setItem('UserData', JSON.stringify(userData));
    localStorage.setItem('Perfil', atrb.perfil ? atrb.perfil.toString() : '0');
  }

  obtenerAtributosUsr(userInfo: any): any {
    const atributos = {
      perfil: 0,
      autoridadRBD : [],
      directorRBD: [],
      mineducRBD: [],
      portal : null
    };
    /*let scope = this.getScope();
    scope = scope.replace('openid', '').trim();*/
    const selectProfile = userInfo.attributes.selectProfile[0];
    switch (selectProfile) {
      case 'director':
        console.log('soy director');
        atributos.perfil = 1;
        atributos.directorRBD = userInfo.attributes.director[0].split(',');
        atributos.portal = 'director';
        break;
      case 'sostenedor':
        console.log('soy sostenedor');
        atributos.perfil = 2;
        atributos.autoridadRBD = userInfo.attributes.sostenedor[0].split(',');
        atributos.portal = 'autoridad';
        break;
      case 'docente':
        console.log('soy docente');
        atributos.perfil = 3;
        atributos.portal = 'docente';
        break;
      case 'apoderado':
        console.log('soy apoderado');
        atributos.perfil = 4;
        atributos.portal = 'apoderado';
        break;
      case 'alumno':
        console.log('soy alumno');
        atributos.perfil = 5;
        atributos.portal = 'estudiante';
        break;
      case 'agencia':
        console.log('soy agencia');
        atributos.perfil = 6;
        atributos.portal = 'agencia';
        break;
      case 'otros':
        console.log('soy otros');
        atributos.perfil = 7;
        atributos.portal = 'otros';
        break;
      case 'mineduc':
        console.log('soy mineduc');
        atributos.perfil = 8;
        atributos.mineducRBD = userInfo.attributes.mineduc[0].split(',');
        atributos.portal = 'mineduc';
        break;
      default:
        console.log('soy otros');
        atributos.perfil = 7;
        atributos.portal = 'otros';
        break;
    }

    return atributos;
  }

  loadUserInfo(): Promise<any> {
    console.log(' this.keycloakAuth2', this.keycloakAuth);
    return new Promise((resolve, reject) => {
      this.keycloakAuth.loadUserProfile().success(profile => {
        resolve(profile);
      }).error(() => {
        reject();
        console.log('Ocurrio un problema al obtener los datos del usuario');
      });
    });
  }

  loadUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.keycloakAuth.loadUserInfo().success(user => {
        resolve(user);
      }).error(() => {
        reject();
        console.log('Ocurrio un problema al obtener los datos del usuario');
      });
    });
  }

  logout(opciones) {
    localStorage.clear();
    return this.keycloakAuth.logout(opciones);
  }

  createLoginUrl(opciones) {
    console.log(opciones);
    return this.keycloakAuth.createLoginUrl(opciones);
  }

  login(opciones) {
    return this.keycloakAuth.login(opciones);
  }

  loginForm(options) {
    return this.keycloakAuth.loginForm(options);
  }

  async isAuthenticated(callback: any) {
    if (callback == null) {
      throw new Error('UserLoginService: Callback en isAuthenticated() no puede ser null');
    }

    const userData = await this.loadUserInfo();
    if (userData != null) {
      if (this.keycloakAuth.onTokenExpired) {
        callback.isLoggedIn('', false);
      } else {
        callback.isLoggedIn('', true);
      }
    } else {
      callback.isLoggedIn('', true);
    }
  }


  async validarAccesoRbd(rbdAcceso: number): Promise<void> {

    if (!localStorage.getItem('UserData')) {
      await this.setUser();
    }

    const userData: any = JSON.parse(localStorage.getItem('UserData'));

    if (!userData) {
      location.pathname = 'login?rbd=' + rbdAcceso;
    } else {
      /*if (userData.perfil === 1) {
        if (userData.director[0] !== rbdAcceso) {
          alert(mensaje);
          window.location.href = 'https://agenciaeducacion.cl/';
        }
      } else if (userData.perfil === 2) {
        if (!userData.sostenedor.includes(rbdAcceso)) {
          alert(mensaje);
          window.location.href = 'https://agenciaeducacion.cl/';
        }
      }*/

      if ((userData.perfil === 1 && userData.director[0] !== rbdAcceso)
        || (userData.perfil === 2 && !userData.sostenedor.includes(rbdAcceso))
      || (userData.perfil === 8 && !userData.mineduc.includes(rbdAcceso))) {
          alert('Estimado usuario usted no tiene permisos para este RBD, sera redireccionado a agenciaeducacion.cl');
          window.location.href = 'https://agenciaeducacion.cl/';
      }
    }
  }
}
