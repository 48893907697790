import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {RbdModel} from '../../../modelos/rbd.model';
import {KeycloakService} from '../../../servicios/keycloak.service';
import {TrackingService} from '../../../servicios/tracking.service';
import {Constantes} from '../../../utils/Constantes';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EncuestaService} from '../../../servicios/encuesta.service';

/**
 * Componente cabecera.
 *
 */
@Component({
  selector: 'app-cabecera',
  templateUrl: './cabecera.component.html',
  styleUrls: ['./cabecera.component.css']
})
export class CabeceraComponent implements OnInit {

  @Input()
  rbdModel: RbdModel = new RbdModel();

  @Input()
  tipo: string;

  @Input()
  perfil: number;

  rut: string;
  versionApp: any;
  anioActual: number;
  nombreUsuario = '';

  public closeResult: string;

  constructor(private router: Router,
              private kcService: KeycloakService,
              private modalService: NgbModal,
              public tracking: TrackingService,
              public encuestaService: EncuestaService
              ) {

    this.versionApp = environment.VERSION;
    this.anioActual = new Date().getFullYear();
  }

  async ngOnInit() {
    const userInfo: any = await this.kcService.loadUserInfo();
    if (userInfo) {
      if (userInfo.attributes.docente || userInfo.attributes.alumno || userInfo.attributes.apoderado) {
        this.nombreUsuario = userInfo.firstName;
      } else {
        this.nombreUsuario = userInfo.firstName + ' ' + userInfo.lastName;
      }
    }
  }

  irPortal(tipo: string) {
    let urlDestino: string;
    if (tipo === 'media') {
      urlDestino = this.router.url.replace(tipo, 'basica');
    } else {
      urlDestino = this.router.url.replace(tipo, 'media');
    }
    this.router.navigate([urlDestino]);
  }

  salir() {
    this.tracking.trackear(this.rbdModel.rbd, 1002, this.tracking.obtenerNombrePorta(this.perfil, this.tipo).id);
    this.abrirModal(this.rbdModel.rbd, this.perfil);
    // this.kcService.logout({redirectUri: 'https://agenciaeducacion.cl/'});
  }

  abrirModal(rbd: string, perfil: number) {
    const modalRef = this.modalService.open(Constantes.MODALS[Constantes.ENCUESTA_MODAL], {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.RBD = rbd;
    modalRef.componentInstance.perfil = perfil;
    modalRef.result.then((result) => {


     /* this.encuestaService.registrar(result).subscribe(data => {
        console.log('Encuesta Enviada');
      }, error => {
        console.log(error);
      });*/
      // this.kcService.logout({redirectUri: 'https://agenciaeducacion.cl/'});
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      console.log(reason);
      this.closeResult = `Dismissed ${Constantes.getDismissReason(reason)}`;
    });
  }
}
