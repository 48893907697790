export class RbdResultadoGeneralBasicaModel {

  public indice: number;
  public matBasica: string;
  public rbd: string;
  public nombreEstab: string;
  public dependencia: string;
  public comuna: string;
  public categoriaDes2016: string;
  public categoriaDes2017: string;
  public categoriaDes2018: string;
  public categoriaDes2019: string;
  public categoriaDes2013: string;
  public categoriaDes2014: string;
  public categoriaDes2015: string;
  public categoriaDesIeEa: string;
  public regionLargo: string;
  public bajaMatricula: string;
  public probInformacion: string;
  public idSlep: string;
  public slep: string;
  public nombreCompleto: string;

  /**
   * Conbstructor de la clase.
   *
   * @param obj
   */
  constructor(obj?: any) {
    this.rbd = obj && obj.rbd || null;
    this.indice = obj && obj.basica && obj.basica.indice || null;
    this.matBasica = obj && obj.basica && obj.basica.mat_basica || null;
    this.nombreEstab = obj && obj.basica && obj.basica.nombre_estab || null;
    this.dependencia = obj && obj.basica && obj.basica.dependencia || null;
    this.comuna = obj && obj.basica && obj.basica.comuna || null;
    this.categoriaDes2016 = obj && obj.basica && obj.basica.categoria_des2016 || null;
    this.categoriaDes2017 = obj && obj.basica && obj.basica.categoria_des2017 || null;
    this.categoriaDes2018 = obj && obj.basica && obj.basica.categoria_des2018 || null;
    this.categoriaDes2019 = obj && obj.basica && obj.basica.categoria_des2019 || null;
    this.categoriaDes2013 = obj && obj.basica && obj.basica.categoria_des2013 || null;
    this.categoriaDes2014 = obj && obj.basica && obj.basica.categoria_des2014 || null;
    this.categoriaDes2015 = obj && obj.basica && obj.basica.categoria_des2015 || null;
    this.categoriaDesIeEa = obj && obj.basica && obj.basica.categoria_des_ie_ea || null;
    this.regionLargo = obj && obj.basica && obj.basica.region_largo || null;
    this.bajaMatricula = obj && obj.basica && obj.basica.baja_matricula || null;
    this.probInformacion = obj && obj.basica && obj.basica.prob_informacion || null;
    this.idSlep = obj && obj.basica && obj.basica.ID_SLEP || null;
    this.slep = obj && obj.basica && obj.basica.SLEP || null;
    if (this.rbd && this.nombreEstab) {
      this.nombreCompleto = `${this.rbd} - ${this.nombreEstab}`;
    }
  }

  /**
   * Metodo que se encarga de traducir el valor proveniente de la base de datos a un valor literal.
   *
   * @param categoriaDesemp string
   * @return descripcion string
   */
  getCategoriaDesempenoLiteral(categoriaDesemp: string): string {
    let descripcion: string;
    switch (categoriaDesemp) {
      case '1':
        descripcion = 'Alto';
        break;
      case '2':
        descripcion = 'Medio';
        break;
      case '3':
        descripcion = 'Medio-Bajo';
        break;
      case '4':
        descripcion = 'Insuficiente';
        break;
      case '5':
        descripcion = 'Medio-Bajo';
        break;
      case '99999':
        descripcion = 'Sin Categoría';
        break;
    }
    return descripcion;
  }
}
