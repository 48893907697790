import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {RegionModel} from '../modelos/region.model';

import {environment} from '../../environments/environment';
import {ComboModel} from '../modelos/combo.model';
import {RegionComunaModel} from '../modelos/region-comuna.model';

@Injectable({
  providedIn: 'root'
})
export class UbicacionService {

  private listaRegiones: RegionModel[] = [{
    id: '1',
    nombre: 'Región de Tarapacá'
  }, {
    id: '2',
    nombre: 'Región de Antofagasta'
  }, {
    id: '3',
    nombre: 'Región de Atacama'
  }, {
    id: '4',
    nombre: 'Región de Coquimbo'
  }, {
    id: '5',
    nombre: 'Región de Valparaíso'
  }, {
    id: '6',
    nombre: 'Región de O’Higgins'
  }, {
    id: '7',
    nombre: 'Región del Maule'
  }, {
    id: '8',
    nombre: 'Región del Bío-Bío'
  }, {
    id: '9',
    nombre: 'Región de La Araucanía'
  }, {
    id: '10',
    nombre: 'Región de Los Lagos'
  }, {
    id: '11',
    nombre: 'Región de Aysén'
  }, {
    id: '12',
    nombre: 'Región de Magallanes'
  }, {
    id: '14',
    nombre: 'Región de Los Ríos'
  }, {
    id: '15',
    nombre: 'Región de Arica y Parinacota'
  }, {
    id: '16',
    nombre: 'Región de Ñuble'
  }, {
    id: 'RM',
    nombre: 'Región Metropolitana de Santiago'
  }];

  constructor(private http: HttpClient) {
  }

  regiones(): Observable<RegionModel[]> {
    return new Observable((observer: any) => {
      observer.next(this.listaRegiones);
      observer.complete();
    });
  }

  sleps(): Observable<ComboModel[]> {
    return this.http.get<ComboModel[]>(`${environment.API_DOMAINS}/slep`).pipe(
      map((result: any) => {
        return result.map((data: any) => {
          return new ComboModel({id: data.id_slep, nombre: data.slep});
        });
      }));
  }

  comunas(): Observable<ComboModel[]> {
    return this.http.get<ComboModel[]>(`${environment.API_DOMAINS}/comunas/listar`).pipe(
      map((result: any) => {
        return result.map((data: any) => {
          return new ComboModel({id: data.comuna, nombre: data.comuna});
        });
      }));
  }

  comunasRegion(): Observable<RegionComunaModel[]> {
    return this.http.get<RegionComunaModel[]>(`${environment.API_DOMAINS}/comunas/listar`).pipe(
      map((result: any) => {
        return result.map((data: any) => {
          return new RegionComunaModel(data);
        });
      }));
  }

}
