export class ResultadoGeneralModel {
  totalAlto: number;
  totalMedio: number;
  totalMedioBajo: number;
  totalInsuficiente: number;
  totalMatriculaAlto: number;
  totalMatriculaMedio: number;
  totalMatriculaMedioBajo: number;
  totalMatriculaInsuficiente: number;
  totalBajaMatricula: number;
  estFaltaInfo: number;
  totalMatriculaSinCat: number;
  totalMatriculaSinCatInformacion: number;

  constructor(obj?: any) {
    this.totalAlto = obj && obj.totalAlto || 0;
    this.totalMedio = obj && obj.totalMedio || 0;
    this.totalMedioBajo = obj && obj.totalMedioBajo || 0;
    this.totalInsuficiente = obj && obj.totalInsuficiente || 0;
    this.totalMatriculaAlto = obj && obj.totalMatriculaAlto || 0;
    this.totalMatriculaMedio = obj && obj.totalMatriculaMedio || 0;
    this.totalMatriculaMedioBajo = obj && obj.totalMatriculaMedioBajo || 0;
    this.totalMatriculaInsuficiente = obj && obj.totalMatriculaInsuficiente || 0;
    this.totalBajaMatricula = obj && obj.totalBajaMatricula || 0;
    this.estFaltaInfo = obj && obj.estFaltaInfo || 0;
    this.totalMatriculaSinCat = obj && obj.totalMatriculaSinCat || 0;
    this.totalMatriculaSinCatInformacion = obj && obj.totalMatriculaSinCatInformacion || 0;

  }

  getPorcentaje(valor, tipoTotal): number {
    let total = 0;
    switch (tipoTotal) {
      case 1:
        total = this.getTotalEstablecimientos();
        break;
      case 2:
        total = this.getTotalMatricula();
        break;
      case 3:
        total = this.totalBajaMatricula + this.estFaltaInfo;
        break;
      case 4:
        total = this.totalMatriculaSinCat + this.totalMatriculaSinCatInformacion;
        break;
    }
    const porcentaje = ((100 * valor) / total);
    return isNaN(porcentaje) ? 0 : parseFloat(porcentaje.toFixed(100 === porcentaje ? 0 : 1));
  }

  getTotalEstablecimientos(): number {
    return (this.totalAlto + this.totalMedio + this.totalMedioBajo + this.totalInsuficiente);
  }

  getTotalMatricula(): number {
    return (this.totalMatriculaAlto + this.totalMatriculaMedio + this.totalMatriculaMedioBajo + this.totalMatriculaInsuficiente);
  }

}
