import {ComboModel} from './combo.model';

export class RegionListaComunaModel {
  id: string;
  nombre: string;
  comunas: ComboModel[] = [];

  constructor(obj?: any) {
    this.id = obj && obj.idRegion || null;
    this.nombre = obj && 'Región ' + obj.nombreRegion || null;
    this.comunas.push({id: obj && obj.idComuna || null, nombre: obj && obj.nombreComuna || null});
  }
}
