import {Component, OnInit, ViewChild} from '@angular/core';
import {LoginModel} from '../../../modelos/login.model';
import {ActivatedRoute} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {IsotopeGridComponent, IsotopeOptions} from 'ngx-isotopex';

@Component({
  selector: 'app-herramientas-orientacion',
  templateUrl: './herramientas-orientacion.component.html',
  styleUrls: ['./herramientas-orientacion.component.css']
})
export class HerramientasOrientacionComponent implements OnInit {
  public tarjetas: any[] = [{
    nombre: 'Liderazgo'
  }, {
    nombre: 'Formacion'
  }, {
    nombre: 'Gestion'
  }, {
    nombre: 'Analisis'
  }, {
    nombre: 'Infografia'
  }];

  @ViewChild('grilla', null) public grilla: IsotopeGridComponent;

  public closeResult: string;
  public loginModel: LoginModel = new LoginModel();

  public isotopeOption: IsotopeOptions = {
    itemSelector: '.grid-item',
  };

  constructor(private route: ActivatedRoute,
              private activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private ngxService: NgxUiLoaderService) {
  }

  ngOnInit() {

  }

  filtrar(filtro: string) {
    this.grilla.filter(filtro);
    console.log('test');
  }

}
