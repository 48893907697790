import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {KeycloakService} from '../../../servicios/keycloak.service';
import {EncuestaService} from '../../../servicios/encuesta.service';

@Component({
  selector: 'app-encuesta-satisfaccion',
  templateUrl: './encuesta-satisfaccion.component.html',
  styleUrls: ['./encuesta-satisfaccion.component.css']
})
export class EncuestaSatisfaccionComponent implements OnInit {

  @Input()
  public perfil: string;

  @Input()
  public RBD: string;

  public encuesta: any = {};
  public mostrarOpciones: number;

  public checkboxesDataList = [];

  public checkboxesDataListUno = [{
    id: 1,
    label: 'Acceso a la página web',
    isChecked: false
  }, {
    id: 2,
    label: 'Diseño poco atractivo',
    isChecked: false
  }, {
    id: 3,
    label: 'Navegación',
    isChecked: false
  }, {
    id: 4,
    label: 'Claridad en la información',
    isChecked: false
  }, {
    id: 5,
    label: 'Acceso a la información',
    isChecked: false
  }, {
    id: 6,
    label: 'Organización de la Información',
    isChecked: false
  }];

  public checkboxesDataListDos = [{
    id: 7,
    label: 'Fácil acceso a la página web',
    isChecked: false
  }, {
    id: 8,
    label: 'Sitio atractivo',
    isChecked: false
  }, {
    id: 9,
    label: 'Buen diseño',
    isChecked: false
  }, {
    id: 10,
    label: 'Es sencillo encontrar la información',
    isChecked: false
  }, {
    id: 11,
    label: 'Información clara',
    isChecked: false
  }, {
    id: 12,
    label: 'Información bien organizada',
    isChecked: false
  }];

  public selectedItemsList = [];
  public checkedIDs = [];

  constructor(public activeModal: NgbActiveModal,
              private kcService: KeycloakService,
              public encuestaService: EncuestaService) { }


  ngOnInit() {
    this.encuesta.perfil = this.perfil;
    this.encuesta.opcion = 0;
    this.mostrarOpciones = 0;
    this.fetchSelectedItems();
    this.fetchCheckedIDs();
  }

  seleccion(opcion: number): void {
    this.encuesta.opcion = opcion;
  }

  finalizarOpcion(): void {

    if (this.encuesta.opcion === 1 || this.encuesta.opcion === 2 || this.encuesta.opcion === 3) {
      this.checkboxesDataList = this.checkboxesDataListUno;
      this.mostrarOpciones = 1;
    } else {
      this.checkboxesDataList = this.checkboxesDataListDos;
      this.mostrarOpciones = 2;
    }
  }

  enviarEncuesta(): void {
    this.encuesta.respuestas = this.selectedItemsList;
    this.encuestaService.registrar(this.encuesta).subscribe(data => {
      console.log('Encuesta Enviada');
    }, error => {
      console.log(error);
    });
    this.kcService.logout({redirectUri: 'https://agenciaeducacion.cl/'});
  }

  changeSelection() {
    this.fetchSelectedItems();
  }

  fetchSelectedItems() {
    this.selectedItemsList = this.checkboxesDataList.filter((value, index) => {
      return value.isChecked;
    });
  }

  fetchCheckedIDs() {
    this.checkedIDs = [];
    this.checkboxesDataList.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedIDs.push(value.id);
      }
    });
  }

}
