import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {RbdMediaModel} from '../modelos/rbd-media.model';
import {RbdResultadoGeneralMediaModel} from '../modelos/rbd-resultado-general-media.model';
import {RbdModel} from '../modelos/rbd.model';
import {map} from 'rxjs/operators';
import {RbdAgpModel} from '../modelos/rbd-agp.model';
import {RbdSimpleModel} from '../modelos/rbd-simple.model';
import {RbdResultadoGeneralBasicaModel} from '../modelos/rbd-resultado-general-basica.model';
import {ItmeResultadoGeneralModel} from '../modelos/itme-resultado-general.model';
import {RbdBusquedaAvanzadaModel} from '../modelos/rbd-busqueda-avanzada.model';
import {ResultadoGeneralModel} from '../modelos/resultado-general.model';

import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RbdService {

  constructor(private http: HttpClient) {
  }

  buscar(nivel: string, rbd: string): Observable<any> {
    return this.http.get<any>(`${environment.API_DOMAINS}/obtener-rbd/${nivel}/${rbd}`).pipe(
      map((result: any) => {
        if (nivel === 'basica') {
          return new RbdModel(result);
        } else {
          return new RbdMediaModel(result);
        }
      }));
  }

  buscar2(nivel: string, rbd: string): Observable<any> {
    return this.http.get<any>(`${environment.API_DOMAINS}/obtener-rbd/${nivel}/${rbd}`).pipe(catchError(this.errorHandler));
  }
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }


  buscarDatos(rol: string, usuario: string): Observable<any> {
    return this.http.get<any>(`${environment.API_DOMAINS}/datos/${rol}/${usuario}`);
  }

  anioPrueba(rbd: string): Observable<RbdAgpModel> {
    return this.http.get<RbdAgpModel>(`${environment.API_DOMAINS}/rbd-agp/${rbd}`);
  }

  filtrarRbd(rbd: string, region?: string): Observable<RbdSimpleModel[]> {
    const body = JSON.stringify({
      filtro: rbd,
      region: region || ''
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    const urlSrv = `${environment.API_DOMAINS}/rbd/buscar-establecimientos`;
    return this.http.post<RbdSimpleModel[]>(urlSrv, body, {headers}).pipe(
      map((result: any) => {
        return result.map((agp: any) => {
          return new RbdSimpleModel(agp);
        });
      }));
  }

  autoridadRbd(rbds: string, limit: number, totalElementos: number, ultimoElemento: any): Observable<ItmeResultadoGeneralModel> {
    const body = JSON.stringify({
      rbds,
      limit: limit ? limit : 10,
      totalElementos: totalElementos ? totalElementos : null,
      LastEvaluatedKey: ultimoElemento ? ultimoElemento : null
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    const urlSrv = `${environment.API_DOMAINS}/rbd/buscarAut`;
    return this.http.post<ItmeResultadoGeneralModel>(urlSrv, body, {headers}).pipe(
      map((result: any) => {
        return new ItmeResultadoGeneralModel(result);
        /*return result.map((rbd: any) => {
          return new ItmeResultadoGeneralModel(rbd);
        });*/
      }));
  }

  resumenEstadistico(region: string,
                     comuna: string,
                     rbds: string,
                     usuario?: string,
                     rol?: string): Observable<RbdResultadoGeneralBasicaModel[]> {
    const parametro: any = {
      nivel: 'basica'
    };
    if (region) {
      parametro.region = region;
    }
    if (comuna) {
      parametro.comuna = comuna;
    }
    if (rbds) {
      parametro.rbds = rbds;
    }

    let urlSrv = `${environment.API_DOMAINS}/rbd/resumen-estadistico`;

    if (rol) {
      urlSrv = `${environment.API_DOMAINS}/rbd/resumen-autoridad`;
      parametro.rol = rol;
      parametro.usuario = usuario;
      delete parametro.rbds;
    }

    const body = JSON.stringify(parametro);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};

    return this.http.post<RbdResultadoGeneralBasicaModel[]>(urlSrv, body, {headers}).pipe(
      map((result: any) => {
        return result.map((rbd: any) => {
          return new RbdResultadoGeneralBasicaModel(rbd);
        });
      }));
  }

  resumenEstadisticoMedia(region: string,
                          comuna: string,
                          rbds: string,
                          usuario?: string,
                          rol?: string): Observable<RbdResultadoGeneralMediaModel[]> {
    const parametro: any = {
      nivel: 'media'
    };
    if (region) {
      parametro.region = region;
    }
    if (comuna) {
      parametro.comuna = comuna;
    }
    if (rbds) {
      parametro.rbds = rbds;
    }

    let urlSrv = `${environment.API_DOMAINS}/rbd/resumen-estadistico`;

    if (rol) {
      urlSrv = `${environment.API_DOMAINS}/rbd/resumen-autoridad`;
      parametro.rol = rol;
      parametro.usuario = usuario;
      delete parametro.rbds;
    }

    const body = JSON.stringify(parametro);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};

    return this.http.post<RbdResultadoGeneralMediaModel[]>(urlSrv, body, {headers}).pipe(
      map((result: any) => {
        return result.map((rbd: any) => {
          return new RbdResultadoGeneralMediaModel(rbd);
        });
      }));
  }

  resumenNacional(nivel: string): Observable<any[]> {
    const urlServicio = `${environment.API_DOMAINS}/rbd/resumen-nacional/${nivel}`;
    return this.http.get<any[]>(urlServicio).pipe(
      map((result: any) => {
        return result.map((rbd: any) => {
          if (nivel === 'media') {
            return new RbdResultadoGeneralMediaModel(rbd);
          } else {
            return new RbdResultadoGeneralBasicaModel(rbd);
          }
        });
      }));
  }

  resumenTablaGeneral(nivel: string, anio: string): Observable<ResultadoGeneralModel> {
    return this.http.get<ResultadoGeneralModel>(`${environment.API_DOMAINS}/rbd/tabla-generales/${nivel}/${anio}`).pipe(
      map((result: any) => {
        return new ResultadoGeneralModel(result);
      }));
  }

  busquedaAvanzada(region: string, comuna: string): Observable<RbdBusquedaAvanzadaModel[]> {
    const body = JSON.stringify({
      region,
      comuna
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    const urlSrv = `${environment.API_DOMAINS}/rbd/busquedaAvanzada`;
    return this.http.post<RbdBusquedaAvanzadaModel[]>(urlSrv, body, {headers}).pipe(
      map((result: any) => {
        return result.map((rbd: any) => {
          return new RbdBusquedaAvanzadaModel(rbd);
        });
      }));
  }

}
