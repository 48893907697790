import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {KeycloakService} from "./keycloak.service";

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService {

  constructor(
    private kcService: KeycloakService,
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    console.log('route', route);
    console.log('state', state);
    const perfil = route.data.perfil;

    const authToken = this.kcService.getToken() || '';

    if (authToken !== '') {
      const userInfo = await this.kcService.loadUserInfo();
      if (userInfo.attributes.hasOwnProperty(perfil)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
}
