import {ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {ComboModel} from '../modelos/combo.model';
import {EncuestaSatisfaccionComponent} from '../componentes/modal/encuesta-satisfaccion/encuesta-satisfaccion.component';

export class Constantes {
  static ENCUESTA_MODAL = 'encuestaSatisfaccion';

  static MODALS = {
    encuestaSatisfaccion: EncuestaSatisfaccionComponent
  };

  static FECHA_VERSION = '27/11/2019 10:55:00';

  static ANIOS: ComboModel[] = [{
    id: '2016',
    nombre: '2016'
  }, {
    id: '2017',
    nombre: '2017'
  }, {
    id: '2018',
    nombre: '2018'
  }, {
    id: '2019',
    nombre: '2019'
  }, {
    id: '2020',
    nombre: '2020'
  }];

  static getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  static getClassLogin(perfil) {
    let clase: string;
    switch (perfil) {
      case 1:
        /*Director*/
        clase = 'color-dark-blue';
        break;
      case 2:
        /*Sostenedor*/
        clase = 'color-pink';
        break;
      case 3:
        /*Docente*/
        clase = 'color-light-blue';
        break;
      case 4:
        /*Apoderado*/
        clase = 'color-yellow';
        break;
      case 5:
        /*Estudiante*/
        clase = 'color-light-green';
        break;
      case 6:
        /*Agencia*/
        clase = 'btn-rojo';
        break;
    }
    return clase;
  }

}
