import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {NgSelectModule} from '@ng-select/ng-select';

import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {ToastrModule} from 'ngx-toastr';

import localeCl from '@angular/common/locales/es-CL';
import {NgLocaleLocalization, NgLocalization, registerLocaleData} from '@angular/common';

registerLocaleData(localeCl, 'es-CL');

import {ChartModule} from 'angular-highcharts';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './componentes/commons/header/header.component';
import {FooterComponent} from './componentes/commons/footer/footer.component';
import {BarraComponent} from './componentes/commons/graficos/barra/barra.component';
import {DirectorMediaComponent} from './componentes/vista/director/director-media/director-media.component';
import {DirectorBasicaComponent} from './componentes/vista/director/director-basica/director-basica.component';
import {AutoridadBasicaComponent} from './componentes/vista/autoridad/autoridad-basica/autoridad-basica.component';
import {AutoridadMediaComponent} from './componentes/vista/autoridad/autoridad-media/autoridad-media.component';
import {ApoderadoMediaComponent} from './componentes/vista/apoderado/apoderado-media/apoderado-media.component';
import {ApoderadoBasicaComponent} from './componentes/vista/apoderado/apoderado-basica/apoderado-basica.component';
import {DocenteBasicaComponent} from './componentes/vista/docente/docente-basica/docente-basica.component';
import {EstudianteBasicaComponent} from './componentes/vista/estudiante/estudiante-basica/estudiante-basica.component';
import {EstudianteMediaComponent} from './componentes/vista/estudiante/estudiante-media/estudiante-media.component';
import {DocenteMediaComponent} from './componentes/vista/docente/docente-media/docente-media.component';
import {CabeceraComponent} from './componentes/commons/cabecera/cabecera.component';
import {GraficoNivelComponent} from './componentes/commons/grafico-nivel/grafico-nivel.component';
import {AuthComponent} from './componentes/auth/auth.component';
import {HerramientasOrientacionComponent} from './componentes/vista/herramientas-orientacion/herramientas-orientacion.component';
import {ResultadosGeneralesComponent} from './componentes/vista/resultados-generales/resultados-generales.component';
import {KeycloakService} from './servicios/keycloak.service';
import {IsotopeModule} from 'ngx-isotopex';
import {LoginComponent} from './componentes/login/login.component';
import {ResultadosBasicaComponent} from './componentes/commons/basica/resultados-basica/resultados-basica.component';
import {ResultadosCategoriaBasicaComponent} from './componentes/commons/basica/resultados-categoria-basica/resultados-categoria-basica.component';
import {ResultadosMediaComponent} from './componentes/commons/media/resultados-media/resultados-media.component';
import {ResultadosCategoriaMediaComponent} from './componentes/commons/media/resultados-categoria-media/resultados-categoria-media.component';
import {AgenciaBasicaComponent} from './componentes/vista/agencia/agencia-basica/agencia-basica.component';
import {AgenciaMediaComponent} from './componentes/vista/agencia/agencia-media/agencia-media.component';
import {OtrosBasicaComponent} from './componentes/vista/otros/otros-basica/otros-basica.component';
import {OtrosMediaComponent} from './componentes/vista/otros/otros-media/otros-media.component';
import {DatosEstablecimientoComponent} from './componentes/commons/datos-establecimiento/datos-establecimiento.component';
import {TabNavegacionComponent} from './componentes/commons/tab-navegacion/tab-navegacion.component';
import {SacCategoriaComponent} from './componentes/commons/sac-categoria/sac-categoria.component';
import {EncuestaSatisfaccionComponent} from './componentes/modal/encuesta-satisfaccion/encuesta-satisfaccion.component';
import { MineducBasicaComponent } from './componentes/vista/mineduc/mineduc-basica/mineduc-basica.component';
import { MineducMediaComponent } from './componentes/vista/mineduc/mineduc-media/mineduc-media.component';
import { InicioComponent } from './componentes/commons/inicio/inicio.component';

export function kcFactory(keycloakService: KeycloakService) {
  return () => keycloakService.init();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    BarraComponent,
    DirectorMediaComponent,
    DirectorBasicaComponent,
    AutoridadBasicaComponent,
    AutoridadMediaComponent,
    ApoderadoMediaComponent,
    ApoderadoBasicaComponent,
    DocenteBasicaComponent,
    EstudianteBasicaComponent,
    EstudianteMediaComponent,
    DocenteMediaComponent,
    CabeceraComponent,
    GraficoNivelComponent,
    AuthComponent,
    HerramientasOrientacionComponent,
    ResultadosGeneralesComponent,
    LoginComponent,
    ResultadosBasicaComponent,
    ResultadosCategoriaBasicaComponent,
    ResultadosMediaComponent,
    ResultadosCategoriaMediaComponent,
    AgenciaBasicaComponent,
    AgenciaMediaComponent,
    OtrosBasicaComponent,
    OtrosMediaComponent,
    DatosEstablecimientoComponent,
    TabNavegacionComponent,
    SacCategoriaComponent,
    EncuestaSatisfaccionComponent,
    MineducBasicaComponent,
    MineducMediaComponent,
    InicioComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true
    }),
    ChartModule,
    HttpClientModule,
    FormsModule,
    AutocompleteLibModule,
    NgxUiLoaderModule,
    NgSelectModule,
    IsotopeModule
  ],
  providers: [NgbActiveModal,
    {provide: NgLocalization, useClass: NgLocaleLocalization}
    , KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: kcFactory,
      deps: [KeycloakService],
      multi: true
    }],
  bootstrap: [AppComponent],
  entryComponents: [EncuestaSatisfaccionComponent]
})
export class AppModule {
}
