import {Component, OnInit , Input} from '@angular/core';
import {LoginModel} from '../../../modelos/login.model';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {RbdResultadoGeneralMediaModel} from '../../../modelos/rbd-resultado-general-media.model';
import {Constantes} from '../../../utils/Constantes';
import {ResultadoGeneralModel} from '../../../modelos/resultado-general.model';
import {RbdResultadoGeneralBasicaModel} from '../../../modelos/rbd-resultado-general-basica.model';
import {RegionListaComunaModel} from '../../../modelos/region-lista-comuna.model';
import {RegionModel} from '../../../modelos/region.model';
import {ComboModel} from '../../../modelos/combo.model';
import {RegionComunaModel} from '../../../modelos/region-comuna.model';
import {UbicacionService} from '../../../servicios/ubicacion.service';
import {RbdService} from '../../../servicios/rbd.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-resultados-generales',
  templateUrl: './resultados-generales.component.html',
  styleUrls: ['./resultados-generales.component.css']
})
export class ResultadosGeneralesComponent implements OnInit {

  public closeResult: string;
  public loginModel: LoginModel = new LoginModel();

  public tablaGenerales: ResultadoGeneralModel = new ResultadoGeneralModel();
  public listaResumenEstadistico: RbdResultadoGeneralBasicaModel[] = [];
  public listaResumenEstadisticoMedia: RbdResultadoGeneralMediaModel[] = [];
  public listaRegiones: RegionListaComunaModel[] = [];
  regiones: RegionModel[] = [];
  comunas: ComboModel[] = [];
  sleps: ComboModel[] = [];
  slepsBackups: ComboModel[] = [];
  anios: ComboModel[] = Constantes.ANIOS;
  regionSeleccionada: string;
  regionSeleccionadaResumen: string;
  comunaSeleccionadaResumen: string;
  anioSeleccionadoResumen: string;
  slepSeleccionadoResumen: string;

  @Input()
  nivel: string;

  @Input()
  slepVisible: boolean;

  @Input()
  perfil: number;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private rbdService: RbdService,
              private ubicacionService: UbicacionService,
              private ngxService: NgxUiLoaderService) {
    this.route.params.subscribe((pathParams) => {
     /*if (pathParams.nivel) {
        this.nivel = pathParams.nivel;
      } else {
        this.router.navigate(['/']);
      }*/
    });
  }

  ngOnInit() {
    this.buscarComunas();
    this.buscarRegiones();
    this.buscarSleps();
    this.buscar();
  }

  seleccionRegion() {

    this.comunaSeleccionadaResumen = null;
    this.slepSeleccionadoResumen = null;
    if (this.regionSeleccionadaResumen) {
      this.comunas = this.listaRegiones.find(x => x.id === this.regionSeleccionadaResumen).comunas;
    } else {
      this.comunas = [];
    }
    this.tablaGenerales = new ResultadoGeneralModel();
    this.buscar();
  }

  seleccionComuna() {
    /*if (this.comunaSeleccionadaResumen) {
      this.tablaGenerales = new ResultadoGeneralModel();
      this.buscarResumenEstadistico();
    }*/
    this.tablaGenerales = new ResultadoGeneralModel();
    this.buscar();
  }

  seleccionSlep() {
    this.tablaGenerales = new ResultadoGeneralModel();
    this.buscar();
  }

  /**
   * Metodo que se encarga de discriminar el nivel en el cual se esta buscando, ya sea busquedas para basica o media.
   */
  buscar() {
    if ('media' === this.nivel) {
      this.buscarResumenEstadisticoMedia();
    } else if ('basica' === this.nivel) {
      this.buscarResumenEstadistico();
    }
  }

  seleccionAnio() {
    this.tablaGenerales = new ResultadoGeneralModel();
    this.procesarResumen(this.listaResumenEstadistico);
  }

  limpiarFiltros() {
    this.comunaSeleccionadaResumen = null;
    this.regionSeleccionadaResumen = null;
    this.anioSeleccionadoResumen = null;
    this.slepSeleccionadoResumen = null;
    this.tablaGenerales = new ResultadoGeneralModel();
    this.buscar();
    this.buscarSleps();
  }

  buscarRegiones() {
    this.ubicacionService.regiones().subscribe(
      (next: RegionModel[]) => {
        this.regiones = next;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  buscarSleps() {
    this.ubicacionService.sleps().subscribe(
      (next: ComboModel[]) => {
        this.sleps = next;
        this.slepsBackups = next;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  buscarComunas() {
    this.ubicacionService.comunasRegion().subscribe(
      (next: RegionComunaModel[]) => {
        next.forEach(item => {
          if (this.listaRegiones.find(r => r.id === item.idRegion)) {
            this.listaRegiones.find(r => r.id === item.idRegion).comunas.push(new ComboModel({
              id: item.idComuna,
              nombre: item.nombreComuna
            }));
          } else {
            this.regiones.push({id: item.idRegion, nombre: item.nombreRegion});
            this.listaRegiones.push(new RegionListaComunaModel(item));
          }
        });
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  buscarResumenEstadistico() {
    this.ngxService.start();
    this.rbdService.resumenEstadistico(this.regionSeleccionadaResumen, this.comunaSeleccionadaResumen, null).subscribe(
      (next: RbdResultadoGeneralBasicaModel[]) => {
        this.listaResumenEstadistico = next;
        this.procesarResumen(next);
        this.ngxService.stop();
      },
      (error: any) => {
        this.ngxService.stop();
        console.log(error);
      }
    );
  }

  buscarResumenEstadisticoMedia() {
    this.ngxService.start();
    this.rbdService.resumenEstadisticoMedia(this.regionSeleccionadaResumen, this.comunaSeleccionadaResumen, null).subscribe(
      (next: RbdResultadoGeneralMediaModel[]) => {
        this.listaResumenEstadisticoMedia = next;
        this.procesarResumenMedia(next);
        this.ngxService.stop();
      },
      (error: any) => {
        this.ngxService.stop();
        console.log(error);
      }
    );
  }

/*  obtenerCodSlep(rbd) {
    if (rbd.idSlep) {
      if (!this.sleps.find(e => e.id === rbd.idSlep)) {
        this.sleps.push(new ComboModel({id: rbd.idSlep, nombre: rbd.slep }));
      } else {
        this.sleps = this.sleps.splice(this.sleps.indexOf({id: rbd.idSlep, nombre: rbd.slep }), 1);
      }
    }
  }*/

  obtenerComunasBySlep(rbd) {
    const existe = this.comunas.length >= 1 ? this.comunas.some(x => x.id === rbd.comuna) : false;

    if (!existe) {
      this.comunas.push(new ComboModel({id: rbd.comuna, nombre: rbd.comuna }));
    }
  }

  filtrarListaSlep(rbd) {
    let slep = null;
    if (rbd.idSlep) {
        slep = this.sleps.find(e => e.id === rbd.idSlep);
    }
    return slep ? slep : null;
  }

  procesarResumen(lista: RbdResultadoGeneralBasicaModel[]) {

    if (this.slepSeleccionadoResumen) {
      this.comunas = [];
      lista = lista.filter(function(rbd) {
        if (rbd.idSlep && rbd.idSlep === this) {
          return rbd;
        }
      }, this.slepSeleccionadoResumen);
    } else {
      this.sleps = this.slepsBackups;
    }
    const listaSlep = [];
    lista.forEach(rbd => {
      if (this.regionSeleccionadaResumen) {
        // this.obtenerCodSlep(rbd);
        if (!this.slepSeleccionadoResumen) {
            const slep = this.filtrarListaSlep(rbd);
            const look = slep !== null ? (listaSlep.length >= 1 ? listaSlep.some(x => x.id === slep.id) : false) : true;
            if (!look) {
                listaSlep.push(slep);
            }
        } else {
          this.obtenerComunasBySlep(rbd);
          /*const existe = this.comunas.length >= 1 ? this.comunas.some(x => x.id === rbd.comuna) : false;

          if (!existe) {
            this.comunas.push(new ComboModel({id: rbd.comuna, nombre: rbd.comuna }));
          }*/
        }
      } else if (this.slepSeleccionadoResumen) {
        this.obtenerComunasBySlep(rbd);
        /*const existe = this.comunas.length >= 1 ? this.comunas.some(x => x.id === rbd.comuna) : false;

        if (!existe) {
          this.comunas.push(new ComboModel({id: rbd.comuna, nombre: rbd.comuna }));
        }*/
      }
      const seleccion = 'categoriaDes2019';
      /*if (this.anioSeleccionadoResumen && this.anioSeleccionadoResumen.trim().length >= 1) {
        seleccion = 'categoriaDes' + this.anioSeleccionadoResumen;
      } else {
        seleccion = 'categoriaDes2019';
      }*/

      switch (rbd[seleccion]) {
        /*switch (rbd.categoriaDes2019) {*/
        case '1':
          /*descripcion = 'Alto';*/
          this.tablaGenerales.totalAlto += 1;
          this.tablaGenerales.totalMatriculaAlto += parseInt(rbd.matBasica, null);
          break;
        case '2':
          /*descripcion = 'Medio';*/
          this.tablaGenerales.totalMedio += 1;
          this.tablaGenerales.totalMatriculaMedio += parseInt(rbd.matBasica, null);
          break;
        case '3':
          /* descripcion = 'Medio-Bajo';*/
          this.tablaGenerales.totalMedioBajo += 1;
          this.tablaGenerales.totalMatriculaMedioBajo += parseInt(rbd.matBasica, null);
          break;
        case '4':
          /*descripcion = 'Insuficiente';*/
          this.tablaGenerales.totalInsuficiente += 1;
          this.tablaGenerales.totalMatriculaInsuficiente += parseInt(rbd.matBasica, null);
          break;
        case '5':
          /* descripcion = 'Medio-Bajo';*/
          this.tablaGenerales.totalMedioBajo += 1;
          this.tablaGenerales.totalMatriculaMedioBajo += parseInt(rbd.matBasica, null);
          break;
        case '99999':
          if ('1' === rbd.bajaMatricula) {
            this.tablaGenerales.totalBajaMatricula += parseInt(rbd.bajaMatricula, null);
            this.tablaGenerales.totalMatriculaSinCat += parseInt(rbd.matBasica, null);
          }
          if ('1' === rbd.probInformacion) {
            this.tablaGenerales.estFaltaInfo += parseInt(rbd.probInformacion, null);
            this.tablaGenerales.totalMatriculaSinCatInformacion += parseInt(rbd.matBasica, null);
          }
        }
    });
    if (this.regionSeleccionadaResumen && !this.slepSeleccionadoResumen) {
        this.sleps = listaSlep;
    }
  }

  procesarResumenMedia(lista: RbdResultadoGeneralMediaModel[]) {
    if (this.slepSeleccionadoResumen) {
      this.comunas = [];
      lista = lista.filter(function(rbd) {
        if (rbd.idSlep && rbd.idSlep === this) {
          return rbd;
        }
      }, this.slepSeleccionadoResumen);
    } else {
      this.sleps = this.slepsBackups;
    }
    const listaSlep = [];
    lista.forEach(rbd => {
      if (this.regionSeleccionadaResumen) {
        // this.obtenerCodSlep(rbd);
        if (!this.slepSeleccionadoResumen) {
          const slep = this.filtrarListaSlep(rbd);
          const look = slep !== null ? (listaSlep.length >= 1 ? listaSlep.some(x => x.id === slep.id) : false) : true;
          if (!look) {
            listaSlep.push(slep);
          }
        } else {
          this.obtenerComunasBySlep(rbd);
          /*const existe = this.comunas.length >= 1 ? this.comunas.some(x => x.id === rbd.comuna) : false;
          if (!existe) {
            this.comunas.push(new ComboModel({id: rbd.comuna, nombre: rbd.comuna }));
          }*/
        }
      } else if (this.slepSeleccionadoResumen) {
        this.obtenerComunasBySlep(rbd);
        /*const existe = this.comunas.length >= 1 ? this.comunas.some(x => x.id === rbd.comuna) : false;
        if (!existe) {
          this.comunas.push(new ComboModel({id: rbd.comuna, nombre: rbd.comuna }));
        }*/
      }

      const seleccion = 'categoriaDes2019';
      /*if (this.anioSeleccionadoResumen && this.anioSeleccionadoResumen.trim().length >= 1) {
        seleccion = 'categoriaDes' + this.anioSeleccionadoResumen;
      } else {
        seleccion = 'categoriaDes2019';
      }*/
      switch (rbd[seleccion]) {
        /*switch (rbd.categoriaDes2019) {*/
        case '1':
          /*descripcion = 'Alto';*/
          this.tablaGenerales.totalAlto += 1;
          this.tablaGenerales.totalMatriculaAlto += parseInt(rbd.matMedia, null);
          break;
        case '2':
          /*descripcion = 'Medio';*/
          this.tablaGenerales.totalMedio += 1;
          this.tablaGenerales.totalMatriculaMedio += parseInt(rbd.matMedia, null);
          break;
        case '3':
          /* descripcion = 'Medio-Bajo';*/
          this.tablaGenerales.totalMedioBajo += 1;
          this.tablaGenerales.totalMatriculaMedioBajo += parseInt(rbd.matMedia, null);
          break;
        case '4':
          /*descripcion = 'Insuficiente';*/
          this.tablaGenerales.totalInsuficiente += 1;
          this.tablaGenerales.totalMatriculaInsuficiente += parseInt(rbd.matMedia, null);
          break;
        case '5':
          /* descripcion = 'Medio-Bajo';*/
          this.tablaGenerales.totalMedioBajo += 1;
          this.tablaGenerales.totalMatriculaMedioBajo += parseInt(rbd.matMedia, null);
          break;
        case '99999':
          if ('1' === rbd.bajaMatricula) {
            this.tablaGenerales.totalBajaMatricula += parseInt(rbd.bajaMatricula, null);
            this.tablaGenerales.totalMatriculaSinCat += parseInt(rbd.matMedia, null);
          }
          if ('1' === rbd.probInformacion) {
            this.tablaGenerales.estFaltaInfo += parseInt(rbd.probInformacion, null);
            this.tablaGenerales.totalMatriculaSinCatInformacion += parseInt(rbd.matMedia, null);
          }
      }
    });
    if (this.regionSeleccionadaResumen && !this.slepSeleccionadoResumen) {
        this.sleps = listaSlep;
    }
  }

  volver() {
    this.location.back();
  }
}
