export class RbdResultadoGeneralMediaModel {

  public matMedia: string;
  public rbd: string;
  public nombreEstab: string;
  public dependencia: string;
  public comuna: string;
  public categoriaDes2013: string;
  public categoriaDes2014: string;
  public categoriaDes2015: string;
  public categoriaDes2016: string;
  public categoriaDes2017: string;
  public categoriaDes2018: string;
  public categoriaDes2019: string;
  public categoriaDesIeEa: string;
  public regionLargo: string;
  public bajaMatricula: string;
  public probInformacion: string;
  public idSlep: string;
  public slep: string;
  public nombreCompleto: string;

  /**
   * Conbstructor de la clase.
   *
   * @param obj
   */
  constructor(obj?: any) {
    this.rbd = obj && obj.rbd || null;
    this.matMedia = obj && obj.media && obj.media.mat_media || null;
    this.nombreEstab = obj && obj.media && obj.media.nombre_estab || null;
    this.dependencia = obj && obj.media && obj.media.dependencia || null;
    this.comuna = obj && obj.media && obj.media.comuna || null;
    this.categoriaDes2013 = obj && obj.media && obj.media.categoria_des2013 || null;
    this.categoriaDes2014 = obj && obj.media && obj.media.categoria_des2014 || null;
    this.categoriaDes2015 = obj && obj.media && obj.media.categoria_des2015 || null;
    this.categoriaDes2016 = obj && obj.media && obj.media.categoria_des2016 || null;
    this.categoriaDes2017 = obj && obj.media && obj.media.categoria_des2017 || null;
    this.categoriaDes2018 = obj && obj.media && obj.media.categoria_des2018 || null;
    this.categoriaDes2019 = obj && obj.media && obj.media.categoria_des2019 || null;
    this.categoriaDesIeEa = obj && obj.media && obj.media.categoria_des_ie_ea || null;
    this.regionLargo = obj && obj.media && obj.media.region_largo || null;
    this.bajaMatricula = obj && obj.media && obj.media.baja_matricula || null;
    this.probInformacion = obj && obj.media && obj.media.prob_informacion || null;
    this.idSlep = obj && obj.media && obj.media.ID_SLEP || null;
    this.slep = obj && obj.media && obj.media.SLEP || null;
    if (this.rbd && this.nombreEstab) {
      this.nombreCompleto = `${this.rbd} - ${this.nombreEstab}`;
    }
  }

  /**
   * Metodo que se encarga de traducir el valor proveniente de la base de datos a un valor literal.
   *
   * @param categoriaDesemp string
   * @return descripcion string
   */
  getCategoriaDesempenoLiteral(categoriaDesemp: string): string {
    let descripcion: string;
    switch (categoriaDesemp) {
      case '1':
        descripcion = 'Alto';
        break;
      case '2':
        descripcion = 'Medio';
        break;
      case '3':
        descripcion = 'Medio-Bajo';
        break;
      case '4':
        descripcion = 'Insuficiente';
        break;
      case '5':
        descripcion = 'Medio-Bajo';
        break;
      case '99999':
        descripcion = 'Sin Categoría';
        break;
    }
    return descripcion;
  }
}
