// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  API_DOMAINS: 'https://api-pcd2020.agenciaorienta.gob.cl/api',

  VERSION: '0.0.1',
  URL_SERVICIO_CLAVEUNICA: 'https://validadorclaveunica.agenciaeducacion.cl/Consulta',
  URL_IMPRESION_RESUMEN: 'https://s3.amazonaws.com/archivos-web.agenciaeducacion.cl/resultados-simce/fileadmin/Repositorio/2018/sintesis/sintesis_2018-',

  USER_POOL_ID: 'us-east-1_fCzUQaSG2',
  CLIENT_ID: '40uifdvnlthk9s6vcb0mka0den',

  COGNITO_DOMAIN: 'https://categoria-desempeno-2019.auth.us-east-1.amazoncognito.com/oauth2/authorize',
  PROVIDER: 'Google',
  REDIRECT_URI: 'https://test-2019.agenciaorienta.gob.cl/auth/goo',
  RESPONSE_TYPE: 'token',
  SCOPE: 'profile email openid aws.cognito.signin.user.admin',

  URL_LOCALIZA: 'https://localizar.agenciaeducacion.cl/',
  /*sso: {
    url: 'https://perfiladordev.agenciaeducacion.cl/auth',
    realm: 'Perfilador',
    clientId: 'agencia',
    credentials: {
      secret: '0009bb5f-447a-4190-b633-744b21436c03'
    }
  }*/
  sso: {
    url: 'https://perfiladorqa.agenciaeducacion.cl/auth',
    realm: 'Perfilador',
    clientId: 'agencia',
    credentials: {
      secret: '8443a33e-515d-4f1e-9fbe-4cadb20cc916'
    }
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
