import {Component, Input, OnInit} from '@angular/core';
import {RbdModel} from '../../../modelos/rbd.model';
import {TrackingService} from '../../../servicios/tracking.service';
import {PortalModel} from '../../../modelos/portal.model';

@Component({
  selector: 'app-tab-navegacion',
  templateUrl: './tab-navegacion.component.html',
  styleUrls: ['./tab-navegacion.component.css']
})
export class TabNavegacionComponent implements OnInit {

  @Input()
  rbdModel: RbdModel = new RbdModel();

  @Input()
  tipo: string;

  @Input()
  perfil: number;

  public portalModel: PortalModel;

  constructor(public tracking: TrackingService) { }

  ngOnInit() {
    this.portalModel = this.tracking.obtenerNombrePorta(this.perfil, this.tipo);
  }

}
