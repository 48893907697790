import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {UserDataModel} from '../modelos/user-data.model';

import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) {
  }

  /**
   * Inicia sesion con el ticket entregado con el servicio de clave unica.
   */
  loginCognito(ticket: string, perfil: string): Observable<UserDataModel> {
    return this.http.get<UserDataModel>(`${environment.API_DOMAINS}/usuarios/login/${perfil}/${ticket}`);
  }

  login(usuario: string, password: string, perfil: number): Observable<UserDataModel> {
    const body = JSON.stringify({
      usuario,
      password,
      perfil
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    const urlSrv = `${environment.API_DOMAINS}/usuarios/login-cognito`;
    return this.http.post<UserDataModel>(urlSrv, body, {headers});
  }

}
