import {Component, Input, OnInit} from '@angular/core';
import {RbdModel} from '../../../modelos/rbd.model';
import {TrackingService} from '../../../servicios/tracking.service';
import {PortalModel} from '../../../modelos/portal.model';

@Component({
  selector: 'app-datos-establecimiento',
  templateUrl: './datos-establecimiento.component.html',
  styleUrls: ['./datos-establecimiento.component.css']
})
export class DatosEstablecimientoComponent implements OnInit {

  @Input()
  rbdModel: RbdModel = new RbdModel();

  @Input()
  tipo: string;

  @Input()
  perfil: number;

  public portalModel: PortalModel;
  public imgIcon: string;

  constructor(public tracking: TrackingService) { }

  ngOnInit() {
    this.portalModel = this.tracking.obtenerNombrePorta(this.perfil, this.tipo);
    this.obtenerIcono(this.perfil);
  }

  obtenerCategoria(categoriaDes2020) {
    let resultadoCategoria: string;
    switch (categoriaDes2020) {
      case '1':
        resultadoCategoria = 'Alto';
        break;
      case '2':
        resultadoCategoria = 'Medio';
        break;
      case '3':
        resultadoCategoria = 'Medio-bajo';
        break;
      case '4':
        resultadoCategoria = 'Insuficiente';
        break;
      case '5':
        resultadoCategoria = 'Medio-bajo';
        break;
      default:
        resultadoCategoria = 'SIN CATEGORÍA';
        break;
    }
    return resultadoCategoria;
  }

  obtenerIcono(perfil: number) {
    switch (perfil) {
      case 1:
        this.imgIcon = 'assets/img/icon-directores.svg';
        break;
      case 2:
        this.imgIcon = 'assets/img/icon-sostenedor.svg';
        break;
      case 3:
        this.imgIcon = 'assets/img/icon-docentes.svg';
        break;
      case 4:
        this.imgIcon = 'assets/img/icon-apoderados.svg';
        break;
      case 5:
        this.imgIcon = 'assets/img/icon-estudiante.svg';
        break;
      case 6:
        this.imgIcon = 'assets/img/logo-agce.svg';
        break;
      case 7:
        this.imgIcon = 'assets/img/logo-otros.svg';
        break;
      case 8:
        this.imgIcon = 'assets/img/agce-vector.svg';
        break;
    }
  }

}
