import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentosService {

  constructor(private http: HttpClient) {
  }

  obtenerUrls(rbd: string, nivel: string, tipo: string): Observable<any> {
    return this.http.get<any>(`${environment.API_DOMAINS}/pdf/${nivel}/${tipo}/${rbd}`);
  }

  obtenerUrlsSostenedor(nivel: string, usuario: string): Observable<any> {
    return this.http.get<any>(`${environment.API_DOMAINS}/pdf-au/${nivel}/${usuario}/`);
  }

  obtenerUrlDocByNombre(nombre: string): Observable<any> {
    return this.http.get<any>(`${environment.API_DOMAINS}/pdf/url/${nombre}`);
  }

}
