import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

import {NgxUiLoaderService} from 'ngx-ui-loader';

import {environment} from '../../../environments/environment';

import {UserDataModel} from '../../modelos/user-data.model';

import {CognitoService} from '../../servicios/cognito.service';
import {LoginService} from '../../servicios/login.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(private ngxService: NgxUiLoaderService,
              private route: ActivatedRoute,
              private router: Router,
              private cognitoService: CognitoService,
              private loginService: LoginService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.ngxService.start();
    this.route.params.subscribe((params: any) => {
      if (params.tipo === 'cu') {
        const data: any = JSON.parse(atob(unescape(params.params)));
        this.route.queryParams.subscribe((queryParams: any) => {

          if (queryParams.ticket) {
            localStorage.clear();
            this.loginService.loginCognito(queryParams.ticket, data.perfil).subscribe(
              (next: UserDataModel) => {

                if (data.perfil === '1' && !next.director) {
                  this.toastr.warning('Usted no posee un establecimiento asociados como director');

                } else if (data.perfil === '2' && !next.sostenedor && !next.autoridad) {
                  this.toastr.warning('Usted no posee establecimientos asociados como sostenedor o autoridad');

                } else {

                  const keyPrefix = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}`;
                  const idTokenKey = `${keyPrefix}.${next.rut}.idToken`;
                  const accessTokenKey = `${keyPrefix}.${next.rut}.accessToken`;
                  const lastUserKey = `${keyPrefix}.LastAuthUser`;

                  localStorage.setItem(idTokenKey, next.idToken);
                  localStorage.setItem(accessTokenKey, next.accessToken);
                  localStorage.setItem(lastUserKey, next.rut);

                  localStorage.setItem('UserData', JSON.stringify(next));

                  if (data.url.startsWith('/inicio')) {
                    localStorage.setItem('Perfil', data.perfil);
                  }
                }

                this.router.navigate([data.url]);
                this.ngxService.stop();

              },
              (error: any) => {
                this.router.navigate([data.url]);
                this.ngxService.stop();

                this.toastr.warning('Usted no posee un establecimientos asociados.');
              }
            );
          } else {
            localStorage.clear();
            window.open(environment.URL_SERVICIO_CLAVEUNICA, '_self');
          }
        });
      } else if (params.tipo === 'goo' && params.params) {
        const url = `${environment.COGNITO_DOMAIN}?identity_provider=${environment.PROVIDER}`
          + `&redirect_uri=${environment.REDIRECT_URI}&response_type=${environment.RESPONSE_TYPE}`
          + `&client_id=${environment.CLIENT_ID}&scope=${environment.SCOPE}`
          + `&state=${params.params}`;
        window.open(url, '_self');
      } else if (params.tipo === 'goo') {
        const qParams = new URLSearchParams(this.router.url
          .replace('/auth/goo', '')
          .replace('#', '&')
        );

        if (qParams.has('error')) {
          const errorDescripcion = qParams.get('error_description');

          if (errorDescripcion.indexOf('EMAIL_DOMAIN_ERR') >= 0) {
            this.toastr.warning('Su dominio no esta autorizado para acceder al portal.');
          } else {
            this.toastr.error('No es posible iniciar sesión Google, intente mas tarde.');
          }
        } else {

          const keyPrefix = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}`;
          const idTokenKey = `${keyPrefix}.idToken`;
          const accessTokenKey = `${keyPrefix}.accessToken`;

          const accessToken = qParams.get('access_token');
          const idToken = qParams.get('id_token');
          const state = qParams.get('state');
          const data: any = JSON.parse(atob(unescape(state)));

          localStorage.clear();
          localStorage.setItem(idTokenKey, idToken);
          localStorage.setItem(accessTokenKey, accessToken);

          const userData: any = {
            accessToken,
            apellidoMaterno: '',
            apellidoPaterno: '',
            autoridad: [],
            director: '*',
            email: '',
            idToken,
            nombre: '',
            rut: '',
            sostenedor: [],
            perfil: data.perfil
          };

          localStorage.setItem('UserData', JSON.stringify(userData));

          localStorage.setItem('Perfil', data.perfil);
          localStorage.setItem('RBD', data.rbd.toString());
        }

        this.router.navigate(['/inicio']);

      } else {
        this.router.navigate(['/inicio']);
      }
    });
  }


}
