export class LoginModel {

  public usr: string;
  public pass: string;
  public rbd: string;
  public tipoUsr: string;
  public perfil: number;

  constructor(obj?: any) {
    this.usr = obj && obj.usuario || null;
    this.pass = obj && obj.pass || null;
    this.rbd = obj && obj.rbd || null;
    this.tipoUsr = obj && obj.tipoUsr || null;
    this.perfil = obj && obj.perfil || null;
  }
}
