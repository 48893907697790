import { Component, OnInit , Input } from '@angular/core';
import {RbdMediaModel} from '../../../../modelos/rbd-media.model';
import {PortalModel} from '../../../../modelos/portal.model';
import {TrackingService} from '../../../../servicios/tracking.service';
import {forkJoin} from 'rxjs';
import {DocumentosService} from '../../../../servicios/documentos.service';

@Component({
  selector: 'app-resultados-media',
  templateUrl: './resultados-media.component.html',
  styleUrls: ['./resultados-media.component.css']
})
export class ResultadosMediaComponent implements OnInit {

  @Input() public rbdModel: RbdMediaModel;

  @Input() public datosMat2medio: any;
  @Input() public datosLect2medio: any;

  @Input() public p4bLect: string;
  @Input() public tab4bLect: number;

  @Input() public p6bLect: string;
  @Input() public tab6bLect: number;

  @Input() public p4bMat: string;
  @Input() public tab4bMat: number;

  @Input() public p6bMat: string;
  @Input() public tab6bMat: number;

  @Input()
  tipo: string;

  @Input()
  perfil: number;

  public portalModel: PortalModel;
  public urlIndicador: string;
  public urlIDPS: string;

  constructor(public tracking: TrackingService,
              private documentosService: DocumentosService) {
    this.portalModel = this.tracking.obtenerNombrePorta(this.perfil, this.tipo);
  }

  ngOnInit() {
    this.portalModel = this.tracking.obtenerNombrePorta(this.perfil, this.tipo);
    const subscribes = [];
    subscribes.push(this.documentosService.obtenerUrlDocByNombre('ElabIndDisNivAp'));
    subscribes.push(this.documentosService.obtenerUrlDocByNombre('MetodologiaCalculoIDPS'));

    forkJoin(subscribes).subscribe(
      (result) => {
        this.urlIndicador = result[0].url;
        this.urlIDPS = result[1].url;
      });
  }

  seleccionarTab(event: any) {
    switch (event.tipo) {
      case '4l':
        this.p4bLect = event.porcentajeSeleccionado;
        this.tab4bLect = event.tabSeleccionado;
        break;
      case '6l':
        this.p6bLect = event.porcentajeSeleccionado;
        this.tab6bLect = event.tabSeleccionado;
        break;
      case '4m':
        this.p4bMat = event.porcentajeSeleccionado;
        this.tab4bMat = event.tabSeleccionado;
        break;
      case '6m':
        this.p6bMat = event.porcentajeSeleccionado;
        this.tab6bMat = event.tabSeleccionado;
        break;
    }
  }

  abrirIndicador() {
    const url = 'https://categoria-desempeno-2019-qa-docs.s3.amazonaws.com/apoyo/Elaboracion-Indicador-Distribucion-por-Niveles-de-Aprendizaje-CDB-2016_vdic2016.pdf';
    window.open(url, '_blank');
  }

}
