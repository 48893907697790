export class RbdBusquedaAvanzadaModel {

  public regionLargo: string;
  public rbd: string;
  public nombreEstab: string;
  public comuna: string;
  public tieneMedia: boolean;
  public tieneBasica: boolean;

  constructor(obj?: any) {
    this.regionLargo = obj && obj.region_largo || null;
    this.rbd = obj && obj.rbd || null;
    this.nombreEstab = obj && obj.nombre_estab || null;
    this.comuna = obj && obj.comuna || null;
    this.tieneMedia = obj && obj.tieneMedia || false;
    this.tieneBasica = obj && obj.tieneBasica || false;
  }
}
