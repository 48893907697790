import {Injectable} from '@angular/core';
import {CognitoUserPool} from 'amazon-cognito-identity-js';

import {environment} from '../../environments/environment';

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void;
}

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  constructor() {
  }

  logout() {
    console.log('CognitoService: Cerrando sesion');
    this.getCurrentUser().signOut();
    localStorage.clear();
  }

  isAuthenticated(callback: LoggedInCallback) {
    if (callback == null) {
      throw new Error('UserLoginService: Callback en isAuthenticated() no puede ser null');
    }

    const cognitoUser = this.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log('UserLoginService: No se pudo recuperar la sesion: ' + err);
          callback.isLoggedIn(err, false);
        } else {
          callback.isLoggedIn(err, session.isValid());
        }
      });
    } else {
      const keyPrefix = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}`;
      const idTokenKey = `${keyPrefix}.idToken`;
      const accessTokenKey = `${keyPrefix}.accessToken`;

      const idToken = localStorage.getItem(idTokenKey);
      const accessToken = localStorage.getItem(accessTokenKey);

      if (idToken && accessToken) {
        callback.isLoggedIn(null, true);
      } else {
        callback.isLoggedIn('Sesion ha expirado', false);
      }
    }
  }

  isAuthenticatedAgce() {
    const keyPrefix = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}`;
    const idTokenKey = `${keyPrefix}.idToken`;
    const accessTokenKey = `${keyPrefix}.accessToken`;

    const idToken = localStorage.getItem(idTokenKey);
    const accessToken = localStorage.getItem(accessTokenKey);

    return !!(idToken && accessToken);
  }

  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  getUserPool() {
    return new CognitoUserPool({
      UserPoolId: environment.USER_POOL_ID,
      ClientId: environment.CLIENT_ID
    });
  }

}
