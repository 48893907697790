export class RegionComunaModel {
  idComuna: string;
  nombreComuna: string;
  idRegion: string;
  nombreRegion: string;

  constructor(obj?: any) {
    this.idComuna = obj && obj.comuna || null;
    this.nombreComuna = obj && obj.comuna || null;
    this.idRegion = obj && obj.regionId || null;
    this.nombreRegion = obj && obj.region || null;
  }
}
