import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {forkJoin} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ItmeResultadoGeneralModel} from '../../../../modelos/itme-resultado-general.model';
import {RbdResultadoGeneralMediaModel} from '../../../../modelos/rbd-resultado-general-media.model';
import {RbdSimpleModel} from '../../../../modelos/rbd-simple.model';
import {ResultadoGeneralModel} from '../../../../modelos/resultado-general.model';
import {DocumentosService} from '../../../../servicios/documentos.service';
import {ExportacionService} from '../../../../servicios/exportacion.service';
import {RbdService} from '../../../../servicios/rbd.service';
import {TrackingService} from '../../../../servicios/tracking.service';
import {LoginModel} from '../../../../modelos/login.model';
import {PortalModel} from '../../../../modelos/portal.model';
import {SafeResourceUrl} from '@angular/platform-browser';
import {RbdMediaModel} from '../../../../modelos/rbd-media.model';
import {KeycloakService} from '../../../../servicios/keycloak.service';

@Component({
  selector: 'app-mineduc-media',
  templateUrl: './mineduc-media.component.html',
  styleUrls: ['./mineduc-media.component.css']
})
export class MineducMediaComponent implements OnInit {

  @Output() cambioPortal: EventEmitter<any> = new EventEmitter();

  public perfil: number;
  public rbd: any;
  public rbdSeleccion: any;
  public keyword = 'nombreCompleto';
  public listaRBD: RbdSimpleModel[] = [];
  public listaRBDAsociados: RbdResultadoGeneralMediaModel[] = [];
  public listaEstablecimientos: RbdSimpleModel[] = [];

  public userData: any;

  public storeGrilla: RbdResultadoGeneralMediaModel[] = [];
  public grillaResultadosGenerales: ItmeResultadoGeneralModel;

  public page = 1;
  public pageSize = 10;
  public collectionSize = 0;
  public portalId = 4;

  public rbds: any[] = [];

  public anioSeleccion = 2019;

  public tablaGenerales: ResultadoGeneralModel = new ResultadoGeneralModel();

  public tieneBasica = false;
  public tieneMedia = false;

  public tab4bLect: number;
  public tab6bLect: number;
  public tab4bMat: number;
  public tab6bMat: number;

  url2016: SafeResourceUrl;
  url2017: SafeResourceUrl;
  url2018: SafeResourceUrl;
  url2019: SafeResourceUrl;

  public datosMat2medio: any;
  public datosLect2medio: any;

  show;
  show2;
  public rbdModel: RbdMediaModel = new RbdMediaModel();

  public loginModel: LoginModel = new LoginModel();

  public portalModel: PortalModel;
  public urlIndicador: string;

  constructor(private rbdService: RbdService,
              private router: Router,
              private route: ActivatedRoute,
              private ngxService: NgxUiLoaderService,
              public tracking: TrackingService,
              private keycloakService: KeycloakService,
              private exportacionService: ExportacionService,
              private documentosService: DocumentosService) {

    this.route.params.subscribe((pathParams) => {
      if (pathParams.rbd) {
        this.keycloakService.validarAccesoRbd(pathParams.rbd);
      }
    });
  }

  ngOnInit() {
    this.tab4bLect = -1;
    this.tab6bLect = -1;
    this.tab4bMat = -1;
    this.tab6bMat = -1;

    const subscribes = [];
    subscribes.push(this.documentosService.obtenerUrlDocByNombre('MetConstruccionIDPS-dic-2016'));

    forkJoin(subscribes).subscribe(
      (result) => {
        this.urlIndicador = result[0].url;
      });

    this.route.params.subscribe((pathParams) => {
      if (pathParams.rbd) {
        this.rbd = pathParams.rbd;
        this.loginModel.perfil = 8;
        this.obtnerRbd(this.rbd);
        this.portalModel = this.tracking.obtenerNombrePorta(this.loginModel.perfil, 'media');
      }
    });
  }

  /**
   * Metodo que se encarga de obtener los datos segun el RBD
   *
   * @param rbd
   */
  obtnerRbd(rbd: string): void {
    this.ngxService.start();
    const subscribes = [];

    subscribes.push(this.rbdService.buscar2('media', rbd));
    subscribes.push(this.rbdService.anioPrueba(rbd));
    forkJoin(subscribes).subscribe(
      (result) => {
        const establecimiento = this.rbdModel.procesarEstablecimientoMedia(result[1]);
        this.datosMat2medio = establecimiento.datosMat2medio;
        this.datosLect2medio = establecimiento.datosLect2medio;
        this.rbdModel = new RbdMediaModel(result[0]);
        this.ngxService.stop();
      }, error => {
        this.ngxService.stop();
        console.log(error);
        if (error.status === 404) {
          alert('Estimado usuario el RBD consultado no existe.');
          window.location.href = 'https://agenciaeducacion.cl/';
        }
      });
  }

  irPortal(rbd: string, portal: string, tipo: string) {
    this.router.navigate(['/' + portal + '/' + tipo + (null !== rbd ? '/' + rbd : '')]);
  }

  irPortalAutoridad(tipo: string) {
    this.router.navigate(['/autoridad/' + tipo]);
  }

  selectEvent(item) {
    this.rbdSeleccion = item;
    this.storeGrilla = [];
    this.storeGrilla = this.listaRBDAsociados.filter(x => x.rbd === this.rbdSeleccion.rbd);
  }

  limpiarBuscador(event) {
    this.storeGrilla = [];
    this.storeGrilla = this.listaRBDAsociados;
  }

  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    if (search && (!isNaN(Number(search)) || search.length > 2)) {
      this.rbdService.filtrarRbd(search).subscribe(
        next => {
          this.listaRBD = next;
          this.rbdSeleccion = null;
        },
        error => {
          this.listaRBD = [];
          this.rbdSeleccion = null;
        }
      );
    } else {
      this.listaRBD = [];
      this.rbdSeleccion = null;
    }
  }

  onFocused(e) {
    console.log(e);
  }

  establecimientosCombo() {
    this.rbds = [];
    this.listaRBDAsociados.forEach(x => {
      this.rbds.push(x.rbd);
      this.listaEstablecimientos.push(this.listaRBD.find(r => r.rbd === parseInt(x.rbd, null)));
    });
  }

  buscarResumenEstadistico() {
    this.ngxService.start();

    const subscribes = [];

    if (this.userData.rol === 'nacional') {
      subscribes.push(this.rbdService.resumenNacional('media'));
    } else {
      const rbds: string[] = [];
      if (this.userData.sostenedor && this.userData.sostenedor.length > 0) {
        rbds.push(...this.userData.sostenedor);
      }

      if (this.userData.autoridad && this.userData.autoridad.length > 0) {
        rbds.push(...this.userData.autoridad);
      }

      subscribes.push(this.rbdService.resumenEstadisticoMedia(null, null, rbds.toString(), this.userData.rut, this.userData.rol));
      subscribes.push(this.rbdService.buscarDatos(this.userData.rol, this.userData.rut));
    }

    forkJoin(subscribes).subscribe(
      (result: any[]) => {
        this.listaRBDAsociados = result[0];
        this.storeGrilla = result[0];
        this.establecimientosCombo();
        this.procesarResumen(result[0], this.anioSeleccion);

        if (this.userData.rol === 'nacional') {
          this.tieneBasica = true;
          this.tieneMedia = true;
        } else {
          this.tieneBasica = result[1].tieneBasica;
          this.tieneMedia = result[1].tieneMedia;
        }

        this.ngxService.stop();
      },
      (error: any) => {
        this.ngxService.stop();
        console.log(error);
      }
    );
  }

  procesarResumen(lista: RbdResultadoGeneralMediaModel[], anio: number) {
    lista.forEach(rbd => {
      switch (rbd['categoriaDes' + this.anioSeleccion]) {
        /*switch (rbd.categoriaDes2019) {*/
        case '1':
          /*descripcion = 'Alto';*/
          this.tablaGenerales.totalAlto += 1;
          this.tablaGenerales.totalMatriculaAlto += parseInt(rbd.matMedia, null);
          break;
        case '2':
          /*descripcion = 'Medio';*/
          this.tablaGenerales.totalMedio += 1;
          this.tablaGenerales.totalMatriculaMedio += parseInt(rbd.matMedia, null);
          break;
        case '3':
          /* descripcion = 'Medio-Bajo';*/
          this.tablaGenerales.totalMedioBajo += 1;
          this.tablaGenerales.totalMatriculaMedioBajo += parseInt(rbd.matMedia, null);
          break;
        case '4':
          /*descripcion = 'Insuficiente';*/
          this.tablaGenerales.totalInsuficiente += 1;
          this.tablaGenerales.totalMatriculaInsuficiente += parseInt(rbd.matMedia, null);
          break;
        case '99999':
          if ('1' === rbd.bajaMatricula) {
            this.tablaGenerales.totalBajaMatricula += parseInt(rbd.bajaMatricula, null);
            this.tablaGenerales.totalMatriculaSinCat += parseInt(rbd.matMedia, null);
          }
          if ('1' === rbd.probInformacion) {
            this.tablaGenerales.estFaltaInfo += parseInt(rbd.probInformacion, null);
            this.tablaGenerales.totalMatriculaSinCatInformacion += parseInt(rbd.matMedia, null);
          }
      }
    });
  }

  filtrarGrilla(llave: string, valor: string, codigoTracking: number) {
    if (null !== codigoTracking) {
      this.tracking.trackear(null, codigoTracking, this.portalId);
    }
    this.storeGrilla = [];
    let storeTmp = [];
    if ('todo' !== llave) {

      if (isNaN(parseInt(valor, null))) {
        if ('conCat' === valor) {
          storeTmp = this.listaRBDAsociados.filter(x => x[llave] !== '99999');
        } else if ('sinCat' === valor) {
          storeTmp = this.listaRBDAsociados.filter(x => x[llave] === '99999');
        }
      } else {
        storeTmp = this.listaRBDAsociados.filter(x => x[llave] === valor);
      }
    } else {
      storeTmp = this.listaRBDAsociados;
    }
    this.storeGrilla = storeTmp;
  }

  descargarListado(): void {
    const elementos = [];
    this.storeGrilla.forEach(item => {
      elementos.push({
        RBD: item.rbd,
        Nombre_Establecimiento: item.nombreEstab,
        Region: item.regionLargo,
        Comuna: item.comuna,
        Dependencia: item.dependencia,
        Matrícula_Basica_2018: item.matMedia,
        Categoria_Desempeno_2019: item.getCategoriaDesempenoLiteral(item.categoriaDes2019)
      });
    });
    this.exportacionService.exportarExcel(elementos, 'Datos_Adminsitrativos');
  }

  setAnio(anio: number) {
    this.tablaGenerales = new ResultadoGeneralModel();
    this.anioSeleccion = anio;
    this.procesarResumen(this.listaRBDAsociados, anio);
  }

  descargarPDF(rbd: string) {
    this.documentosService.obtenerUrls(rbd, 'Media', 'Director').subscribe(
      (next: any) => {
        if (next.a2019) {
          window.open(next.a2019, '_blank');
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  descargarPDFAu() {
    this.documentosService.obtenerUrlsSostenedor('Media', this.userData.rut).subscribe(
      (next: any) => {
        if (next.a2019) {
          window.open(next.a2019, '_blank');
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  abrirLink(link: string) {
    window.open(link, '_blank');
  }

}
