import { Component, OnInit } from '@angular/core';
import {RegionModel} from '../../modelos/region.model';
import {forkJoin} from 'rxjs';
import {RegionComunaModel} from '../../modelos/region-comuna.model';
import {ComboModel} from '../../modelos/combo.model';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {UbicacionService} from '../../servicios/ubicacion.service';
import {RbdSimpleModel} from '../../modelos/rbd-simple.model';
import {Constantes} from '../../utils/Constantes';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RbdService} from '../../servicios/rbd.service';
import {KeycloakService} from '../../servicios/keycloak.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RbdModel} from '../../modelos/rbd.model';
import {LoginModel} from '../../modelos/login.model';
import {ResultadoGeneralModel} from '../../modelos/resultado-general.model';
import {RbdResultadoGeneralBasicaModel} from '../../modelos/rbd-resultado-general-basica.model';
import {RegionListaComunaModel} from '../../modelos/region-lista-comuna.model';
import {TrackingService} from '../../servicios/tracking.service';
import {CognitoService} from '../../servicios/cognito.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public closeResult: string;
  public rbdModel: RbdModel = new RbdModel();
  public loginModel: LoginModel = new LoginModel();

  public tablaGenerales: ResultadoGeneralModel = new ResultadoGeneralModel();
  public listaResumenEstadistico: RbdResultadoGeneralBasicaModel[] = [];

  public listaRegiones: RegionListaComunaModel[] = [];
  regiones: RegionModel[] = [];
  comunas: ComboModel[] = [];
  anios: ComboModel[] = Constantes.ANIOS;
  regionSeleccionada: string;
  regionSeleccionadaResumen: string;
  comunaSeleccionadaResumen: string;
  anioSeleccionadoResumen: string;
  public perfil: number;
  public portal: string;

  rbdSeleccion: any;
  keyword = 'nombreCompleto';
  listaRBD: RbdSimpleModel[] = [];
  show;
  show2;
  show3;
  userData;
  constructor(public tracking: TrackingService,
              private modalService: NgbModal,
              private router: Router,
              private route: ActivatedRoute,
              private activeModal: NgbActiveModal,
              private cognitoService: CognitoService,
              private rbdService: RbdService,
              private ngxService: NgxUiLoaderService,
              private ubicacionService: UbicacionService,
              private kcService: KeycloakService) {
    this.anioSeleccionadoResumen = Constantes.ANIOS.find(x => x.id === '2019').id;
    if (!localStorage.getItem('UserData')) {
      this.kcService.setUser().then();
    }
  }

  async ngOnInit() {
    await this.kcService.isAuthenticated(this);
  }

  isLoggedIn(message: string, loggedIn: boolean): void {
    if (loggedIn) {

      const userData: any = JSON.parse(localStorage.getItem('UserData'));
      const params = new URLSearchParams(this.router.url.split('?')[1]);
      const rbdparam: any = params.get('rbd');
      if (userData && userData.perfil) {
        this.portal = userData.portal;
        this.kcService.validarAccesoRbd(rbdparam);

        if (rbdparam) {
          this.rbdService.buscar(null, rbdparam).subscribe((result: any) => {
            this.rbdModel = new RbdModel(result);
            this.irPortal((this.rbdModel.tieneBasica ? 'basica' : 'media'), rbdparam);
          });
        }
      } else {
        localStorage.clear();
      }
    } else {
      localStorage.clear();
    }
  }

  irPortal(tipo: string, rbd: string) {
    this.router.navigate(['/' + this.portal + '/' + tipo + '/' + rbd]);
  }

  getPerfil(perfil: number) {
    switch (this.perfil) {
      case 1:
        /*Director*/
        this.portal = 'director';
        break;
      case 2:
        /*Sostenedor*/
        this.portal = 'autoridad';
        break;
      case 3:
        /*Docente*/
        this.portal = 'docente';
        break;
      case 4:
        /*Apoderado*/
        this.portal = 'apoderado';
        break;
      case 5:
        /*Estudiante*/
        this.portal = 'estudiante';
        break;
      case 6:
        /*Agencia*/
        this.portal = 'agencia';
        break;
      case 7:
        /*Otros*/
        this.portal = 'otros';
        break;
      case 8:
        /*mineduc*/
        this.portal = 'mineduc';
        break;
    }
  }

  cargarInicio() {
    this.ngxService.start();

    const subscribes = [];
    subscribes.push(this.ubicacionService.comunasRegion());
    subscribes.push(this.rbdService.resumenEstadistico(this.regionSeleccionadaResumen, this.comunaSeleccionadaResumen, null));

    forkJoin(subscribes).subscribe((result: any[]) => {
      const regionesTmp = result[0].reduce((accum: any, comuna: RegionComunaModel) => {
        if (!accum[comuna.idRegion]) {
          accum[comuna.idRegion] = {
            id: comuna.idRegion,
            nombre: 'Región ' + comuna.nombreRegion,
            comunas: []
          };
        }

        accum[comuna.idRegion].comunas.push(new ComboModel({
          id: comuna.idComuna,
          nombre: comuna.nombreComuna
        }));

        return accum;
      }, {});

      this.regiones = Object.values(regionesTmp);
      this.listaResumenEstadistico = result[1];
      this.procesarResumen(result[1]);

      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.log(error);
    });

  }

  buscarResumenEstadistico() {
    this.ngxService.start();
    // if (this.regionSeleccionadaResumen || this.comunaSeleccionadaResumen) {
    this.rbdService.resumenEstadistico(this.regionSeleccionadaResumen, this.comunaSeleccionadaResumen, null).subscribe(
      (next: RbdResultadoGeneralBasicaModel[]) => {
        this.ngxService.stop();
        this.listaResumenEstadistico = next;
        this.procesarResumen(next);
      },
      (error: any) => {
        this.ngxService.stop();
        console.log(error);
      }
    );
    /*} else {
      this.rbdService.resumenTablaGeneral('basica', this.anioSeleccionadoResumen ? this.anioSeleccionadoResumen : '2019').subscribe(
        (next: ResultadoGeneralModel) => {
          this.ngxService.stop();
          this.tablaGenerales = next;
        },
        (error: any) => {
          this.ngxService.stop();
          console.log(error);
        }
      );
    }*/
  }

  procesarResumen(lista: RbdResultadoGeneralBasicaModel[]) {
    lista.forEach(rbd => {
      let seleccion = '';
      if (this.anioSeleccionadoResumen && this.anioSeleccionadoResumen.trim().length >= 1) {
        seleccion = 'categoriaDes' + this.anioSeleccionadoResumen;
      } else {
        seleccion = 'categoriaDes2019';
      }
      switch (rbd[seleccion]) {
        /*switch (rbd.categoriaDes2019) {*/
        case '1':
          /*descripcion = 'Alto';*/
          this.tablaGenerales.totalAlto += 1;
          this.tablaGenerales.totalMatriculaAlto += parseInt(rbd.matBasica, null);
          break;
        case '2':
          /*descripcion = 'Medio';*/
          this.tablaGenerales.totalMedio += 1;
          this.tablaGenerales.totalMatriculaMedio += parseInt(rbd.matBasica, null);
          break;
        case '3':
          /* descripcion = 'Medio-Bajo';*/
          this.tablaGenerales.totalMedioBajo += 1;
          this.tablaGenerales.totalMatriculaMedioBajo += parseInt(rbd.matBasica, null);
          break;
        case '4':
          /*descripcion = 'Insuficiente';*/
          this.tablaGenerales.totalInsuficiente += 1;
          this.tablaGenerales.totalMatriculaInsuficiente += parseInt(rbd.matBasica, null);
          break;
        case '99999':
          if ('1' === rbd.bajaMatricula) {
            this.tablaGenerales.totalBajaMatricula += parseInt(rbd.bajaMatricula, null);
            this.tablaGenerales.totalMatriculaSinCat += parseInt(rbd.matBasica, null);
          }
          if ('1' === rbd.probInformacion) {
            this.tablaGenerales.estFaltaInfo += parseInt(rbd.probInformacion, null);
            this.tablaGenerales.totalMatriculaSinCatInformacion += parseInt(rbd.matBasica, null);
          }
      }
    });
  }

  buscarRbd(rbds: any) {
    this.ngxService.start();
    const subscribes = [];
    subscribes.push(this.rbdService.resumenEstadistico(null, null, null));
    subscribes.push(this.ubicacionService.regiones());

    forkJoin(subscribes).subscribe((result: any[]) => {
      const lista = result[0];
      this.listaResumenEstadistico = lista;
      this.procesarResumen(lista);
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.log(error);
    });
  }

  selectEvent(item) {
    // do something with selected item
    console.log(item);
    this.rbdSeleccion = item;
  }

  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    if (search && (!isNaN(Number(search)) || search.length > 2)) {
      this.rbdService.filtrarRbd(search, this.regionSeleccionada).subscribe(
        next => {
          this.listaRBD = next;
          this.rbdSeleccion = null;
        },
        error => {
          this.listaRBD = [];
          this.rbdSeleccion = null;
        }
      );
    } else {
      this.listaRBD = [];
      this.rbdSeleccion = null;
    }
  }

  onFocused(e) {
    console.log(e);
  }

  cambiarRegion() {
    this.rbdSeleccion = null;
  }

  seleccionRegion(event: any) {
    console.log(event);
    this.comunaSeleccionadaResumen = null;
    this.tablaGenerales = new ResultadoGeneralModel();
    if (event) {
      this.comunas = event.comunas;
    }

    this.buscarResumenEstadistico();
  }

/*  seleccionComuna() {
    this.tablaGenerales = new ResultadoGeneralModel();
    this.buscarResumenEstadistico();
  }*/

  seleccionAnio() {
    this.tablaGenerales = new ResultadoGeneralModel();
    this.procesarResumen(this.listaResumenEstadistico);
  }

/*  limpiarFiltros() {
    this.comunaSeleccionadaResumen = null;
    this.regionSeleccionadaResumen = null;
    this.anioSeleccionadoResumen = '2019';
    this.buscarResumenEstadistico();
  }*/

  irA() {
    window.open('https://www.agenciaeducacion.cl/orientacion/herramientas-de-orientacion/talleres-de-orientacion/', '_blank');
  }

  salir() {
    const opciones = {};
    this.kcService.logout(opciones);
  }

}
