export class RbdMediaModel {

  public bajaMatricula: string;
  public brechaLect2m2016: string;
  public brechaLect2m2017: string;
  public brechaLect2m2018: string;
  public brechaMate2m2016: string;
  public brechaMate2m2017: string;
  public brechaMate2m2018: string;
  public casoEspecialProgreso: string;
  public categoriaDes2016: string;
  public categoriaDes2017: string;
  public categoriaDes2018: string;
  public categoriaDes2019: string;
  public categoriaDes2020: string;
  public categoriaDesIeEa: string;
  public comuna: string;
  public deltaLect2m: string;
  public deltaMate2m: string;
  public dependencia: string;
  public dim2mAmAa2016: string;
  public dim2mAmAa2017: string;
  public dim2mAmAa2018: string;
  public dim2mAmMe2016: string;
  public dim2mAmMe2017: string;
  public dim2mAmMe2018: string;
  public dim2mCcAo2016: string;
  public dim2mCcAo2017: string;
  public dim2mCcAo2018: string;
  public dim2mCcAr2016: string;
  public dim2mCcAr2017: string;
  public dim2mCcAr2018: string;
  public dim2mCcAs2016: string;
  public dim2mCcAs2017: string;
  public dim2mCcAs2018: string;
  public dim2mHvAc2016: string;
  public dim2mHvAc2017: string;
  public dim2mHvAc2018: string;
  public dim2mHvHa2016: string;
  public dim2mHvHa2017: string;
  public dim2mHvHa2018: string;
  public dim2mHvVa2016: string;
  public dim2mHvVa2017: string;
  public dim2mHvVa2018: string;
  public dim2mPfPa2016: string;
  public dim2mPfPa2017: string;
  public dim2mPfPa2018: string;
  public dim2mPfSp2016: string;
  public dim2mPfSp2017: string;
  public dim2mPfSp2018: string;
  public dim2mPfVd2016: string;
  public dim2mPfVd2017: string;
  public dim2mPfVd2018: string;
  public directorNombre: string;
  public directorRut: string;
  public eeNuevo: string;
  public estEg2015: string;
  public estEg2015Edsup: string;
  public estEg2016: string;
  public estEg2016Edsup: string;
  public estEg2017: string;
  public estEg2017Edsup: string;
  public estTitu2016Eg2015: string;
  public estTitu2016EgRezagado: string;
  public estTitu2017Eg2016: string;
  public estTitu2017EgRezagado: string;
  public estTitu2018Eg2017: string;
  public estTitu2018EgRezagado: string;
  public ind2mAm2016: string;
  public ind2mAm2017: string;
  public ind2mAm2018: string;
  public ind2mAsi2016: string;
  public ind2mAsi2017: string;
  public ind2mAsi2018: string;
  public ind2mCc2016: string;
  public ind2mCc2017: string;
  public ind2mCc2018: string;
  public ind2mEq: string;
  public ind2mHv2016: string;
  public ind2mHv2017: string;
  public ind2mHv2018: string;
  public ind2mPf2016: string;
  public ind2mPf2017: string;
  public ind2mPf2018: string;
  public ind2mTp2016: string;
  public ind2mTp2017: string;
  public ind2mTp2018: string;
  public indRet2015: string;
  public indRet2016: string;
  public indRet2017: string;
  public marchablMedia: string;
  public matMedia: string;
  public nombreEstab: string;
  public por2mAsiDestacada2016: string;
  public por2mAsiDestacada2017: string;
  public por2mAsiDestacada2018: string;
  public por2mAsiGrave2016: string;
  public por2mAsiGrave2017: string;
  public por2mAsiGrave2018: string;
  public por2mAsiNormal2016: string;
  public por2mAsiNormal2017: string;
  public por2mAsiNormal2018: string;
  public por2mAsiReiterada2016: string;
  public por2mAsiReiterada2017: string;
  public por2mAsiReiterada2018: string;
  public porRet2015: string;
  public porRet2016: string;
  public porRet2017: string;
  public probInformacion: string;
  public promIndAm: string;
  public promIndAsistencia: string;
  public promIndCc: string;
  public promIndEda: string;
  public promIndEdaIe: string;
  public promIndEdaIeEa: string;
  public promIndEquidadGenero: string;
  public promIndPf: string;
  public promIndPtjeSimce: string;
  public promIndRetencion: string;
  public promIndTendencia: string;
  public promIndTitulacionTp: string;
  public promIndVs: string;
  public promLect2m2016: string;
  public promLect2m2017: string;
  public promLect2m2018: string;
  public promMate2m2016: string;
  public promMate2m2017: string;
  public promMate2m2018: string;
  public rbd: string;
  public regionCorto: string;
  public regionLargo: string;
  public tipoEstab: string;
  public valorBrechaLect2m2016: string;
  public valorBrechaLect2m2017: string;
  public valorBrechaLect2m2018: string;
  public valorBrechaMate2m2016: string;
  public valorBrechaMate2m2017: string;
  public valorBrechaMate2m2018: string;
  public variacionLect2m20162015: string;
  public variacionLect2m20172016: string;
  public variacionLect2m20182017: string;
  public variacionMate2m20162015: string;
  public variacionMate2m20172016: string;
  public variacionMate2m20182017: string;

  public promIndEdaNac: string;
  public promIndCcNac: string;
  public promIndPfNac: string;
  public promIndVsNac: string;
  public promIndAsistenciaNac: string;
  public promIndRetencionNac: string;
  public promIndEquidadGeneroNac: string;
  public promIndTitulacionTpNac: string;
  public promIndTendenciaNac: string;
  public promIndPtjeSimceNac: string;

  public tieneMedia: boolean;
  public tieneBasica: boolean;

  constructor(obj?: any) {
    this.dim2mHvAc2016 = obj && obj.dim_2m_hv_ac_2016 || null;
    this.por2mAsiReiterada2018 = obj && obj.por_2m_asi_reiterada_2018 || null;
    this.dim2mHvAc2017 = obj && obj.dim_2m_hv_ac_2017 || null;
    this.por2mAsiReiterada2017 = obj && obj.por_2m_asi_reiterada_2017 || null;
    this.dim2mHvAc2018 = obj && obj.dim_2m_hv_ac_2018 || null;
    this.por2mAsiReiterada2016 = obj && obj.por_2m_asi_reiterada_2016 || null;
    this.estEg2016 = obj && obj.est_eg_2016 || null;
    this.estEg2015 = obj && obj.est_eg_2015 || null;
    this.estTitu2017Eg2016 = obj && obj.est_titu_2017_eg_2016 || null;
    this.estEg2017 = obj && obj.est_eg_2017 || null;
    this.estEg2017Edsup = obj && obj.est_eg_2017_edsup || null;
    this.categoriaDes2017 = obj && obj.categoria_des2017 || null;
    this.categoriaDes2018 = obj && obj.categoria_des2018 || null;
    this.ind2mCc2017 = obj && obj.ind_2m_cc_2017 || null;
    this.ind2mCc2016 = obj && obj.ind_2m_cc_2016 || null;
    this.dim2mCcAo2018 = obj && obj.dim_2m_cc_ao_2018 || null;
    this.dim2mCcAo2017 = obj && obj.dim_2m_cc_ao_2017 || null;
    this.dim2mCcAo2016 = obj && obj.dim_2m_cc_ao_2016 || null;
    this.categoriaDes2019 = obj && obj.categoria_des2019 || null;
    this.categoriaDes2020 = obj && obj.categoria_des2020 || null;
    this.marchablMedia = obj && obj.marchabl_media || null;
    this.regionCorto = obj && obj.region_corto || null;
    this.ind2mTp2018 = obj && this.aproximacionValor(obj.ind_2m_tp_2018) || null;
    this.ind2mTp2016 = obj && obj.ind_2m_tp_2016 || null;
    this.rbd = obj && obj.rbd || null;
    this.ind2mTp2017 = obj && obj.ind_2m_tp_2017 || null;
    this.promIndPf = obj && this.aproximacionValor(obj.prom_ind_pf) || null;
    this.promIndAsistencia = obj && this.aproximacionValor(obj.prom_ind_asistencia) || null;
    this.ind2mEq = obj && obj.ind_2m_eq || null;
    this.variacionMate2m20182017 = obj && obj.variacion_mate2m_2018_2017 || null;
    this.promLect2m2018 = obj && obj.prom_lect2m_2018 || null;
    this.brechaMate2m2018 = obj && obj.brecha_mate2m_2018 || null;
    this.deltaMate2m = obj && obj.delta_mate2m || null;
    this.brechaMate2m2016 = obj && obj.brecha_mate2m_2016 || null;
    this.brechaMate2m2017 = obj && obj.brecha_mate2m_2017 || null;
    this.dim2mPfVd2017 = obj && obj.dim_2m_pf_vd_2017 || null;
    this.dim2mHvHa2017 = obj && obj.dim_2m_hv_ha_2017 || null;
    this.promIndTitulacionTp = obj && this.aproximacionValor(obj.prom_ind_titulacion_tp) || null;
    this.dim2mPfVd2016 = obj && obj.dim_2m_pf_vd_2016 || null;
    this.dim2mHvHa2018 = obj && obj.dim_2m_hv_ha_2018 || null;
    this.eeNuevo = obj && obj.ee_nuevo || null;
    this.matMedia = obj && obj.mat_media || null;
    this.promIndCc = obj && this.aproximacionValor(obj.prom_ind_cc) || null;
    this.dim2mPfVd2018 = obj && obj.dim_2m_pf_vd_2018 || null;
    this.dim2mHvHa2016 = obj && obj.dim_2m_hv_ha_2016 || null;
    this.estTitu2016Eg2015 = obj && obj.est_titu_2016_eg_2015 || null;
    this.promIndAm = obj && this.aproximacionValor(obj.prom_ind_am) || null;
    this.promIndEquidadGenero = obj && this.aproximacionValor(obj.prom_ind_equidad_genero) || null;
    this.deltaLect2m = obj && obj.delta_lect2m || null;
    this.ind2mAsi2018 = obj && obj.ind_2m_asi_2018 || null;
    this.ind2mAsi2017 = obj && obj.ind_2m_asi_2017 || null;
    this.por2mAsiGrave2017 = obj && obj.por_2m_asi_grave_2017 || null;
    this.estEg2015Edsup = obj && obj.est_eg_2015_edsup || null;
    this.por2mAsiGrave2018 = obj && obj.por_2m_asi_grave_2018 || null;
    this.por2mAsiGrave2016 = obj && obj.por_2m_asi_grave_2016 || null;
    this.promIndEdaIe = obj && this.aproximacionValor(obj.prom_ind_eda_ie) || null;
    this.categoriaDesIeEa = obj && obj.categoria_des_ie_ea || null;
    this.valorBrechaMate2m2017 = obj && obj.valor_brecha_mate2m_2017 || null;
    this.porRet2017 = obj && obj.por_ret_2017 || null;
    this.valorBrechaMate2m2018 = obj && obj.valor_brecha_mate2m_2018 || null;
    this.ind2mAsi2016 = obj && obj.ind_2m_asi_2016 || null;
    this.porRet2016 = obj && obj.por_ret_2016 || null;
    this.porRet2015 = obj && obj.por_ret_2015 || null;
    this.valorBrechaMate2m2016 = obj && obj.valor_brecha_mate2m_2016 || null;
    this.dim2mHvVa2016 = obj && obj.dim_2m_hv_va_2016 || null;
    this.promLect2m2017 = obj && obj.prom_lect2m_2017 || null;
    this.promLect2m2016 = obj && obj.prom_lect2m_2016 || null;
    this.dim2mPfPa2016 = obj && obj.dim_2m_pf_pa_2016 || null;
    this.dim2mPfPa2018 = obj && obj.dim_2m_pf_pa_2018 || null;
    this.dim2mHvVa2017 = obj && obj.dim_2m_hv_va_2017 || null;
    this.dim2mPfPa2017 = obj && obj.dim_2m_pf_pa_2017 || null;
    this.dim2mHvVa2018 = obj && obj.dim_2m_hv_va_2018 || null;
    this.bajaMatricula = obj && obj.baja_matricula || null;
    this.promIndRetencion = obj && this.aproximacionValor(obj.prom_ind_retencion) || null;
    this.ind2mPf2016 = obj && obj.ind_2m_pf_2016 || null;
    this.dim2mCcAs2017 = obj && obj.dim_2m_cc_as_2017 || null;
    this.ind2mPf2017 = obj && obj.ind_2m_pf_2017 || null;
    this.dim2mCcAs2016 = obj && obj.dim_2m_cc_as_2016 || null;
    this.ind2mPf2018 = obj && obj.ind_2m_pf_2018 || null;
    this.promIndPtjeSimce = obj && this.aproximacionValor(obj.prom_ind_ptje_simce) || null;
    this.estTitu2016EgRezagado = obj && obj.est_titu_2016_eg_rezagado || null;
    this.estTitu2017EgRezagado = obj && obj.est_titu_2017_eg_rezagado || null;
    this.estTitu2018EgRezagado = obj && obj.est_titu_2018_eg_rezagado || null;
    this.promIndEda = obj && this.aproximacionValor(obj.prom_ind_eda) || null;
    this.ind2mAm2017 = obj && obj.ind_2m_am_2017 || null;
    this.ind2mAm2016 = obj && obj.ind_2m_am_2016 || null;
    this.ind2mAm2018 = obj && obj.ind_2m_am_2018 || null;
    this.variacionLect2m20182017 = obj && obj.variacion_lect2m_2018_2017 || null;
    this.valorBrechaLect2m2017 = obj && obj.valor_brecha_lect2m_2017 || null;
    this.promMate2m2018 = obj && obj.prom_mate2m_2018 || null;
    this.dim2mCcAr2016 = obj && obj.dim_2m_cc_ar_2016 || null;
    this.valorBrechaLect2m2016 = obj && obj.valor_brecha_lect2m_2016 || null;
    this.promMate2m2017 = obj && obj.prom_mate2m_2017 || null;
    this.valorBrechaLect2m2018 = obj && obj.valor_brecha_lect2m_2018 || null;
    this.promIndEdaIeEa = obj && this.aproximacionValor(obj.prom_ind_eda_ie_ea) || null;
    this.promMate2m2016 = obj && obj.prom_mate2m_2016 || null;
    this.promIndTendencia = obj && this.aproximacionValor(obj.prom_ind_tendencia) || null;
    this.dim2mCcAr2017 = obj && obj.dim_2m_cc_ar_2017 || null;
    this.dim2mCcAr2018 = obj && obj.dim_2m_cc_ar_2018 || null;
    this.brechaLect2m2017 = obj && obj.brecha_lect2m_2017 || null;
    this.brechaLect2m2018 = obj && obj.brecha_lect2m_2018 || null;
    this.brechaLect2m2016 = obj && obj.brecha_lect2m_2016 || null;
    this.variacionMate2m20162015 = obj && obj.variacion_mate2m_2016_2015 || null;
    this.indRet2017 = obj && obj.ind_ret_2017 || null;
    this.indRet2015 = obj && obj.ind_ret_2015 || null;
    this.indRet2016 = obj && obj.ind_ret_2016 || null;
    this.estEg2016Edsup = obj && obj.est_eg_2016_edsup || null;
    this.variacionLect2m20162015 = obj && obj.variacion_lect2m_2016_2015 || null;
    this.dim2mAmAa2017 = obj && obj.dim_2m_am_aa_2017 || null;
    this.dim2mAmAa2016 = obj && obj.dim_2m_am_aa_2016 || null;
    this.nombreEstab = obj && obj.nombre_estab || null;
    this.dim2mAmAa2018 = obj && obj.dim_2m_am_aa_2018 || null;
    this.por2mAsiNormal2016 = obj && obj.por_2m_asi_normal_2016 || null;
    this.por2mAsiDestacada2016 = obj && obj.por_2m_asi_destacada_2016 || null;
    this.tipoEstab = obj && obj.tipo_estab || null;
    this.por2mAsiDestacada2017 = obj && obj.por_2m_asi_destacada_2017 || null;
    this.por2mAsiNormal2017 = obj && obj.por_2m_asi_normal_2017 || null;
    this.por2mAsiDestacada2018 = obj && obj.por_2m_asi_destacada_2018 || null;
    this.por2mAsiNormal2018 = obj && obj.por_2m_asi_normal_2018 || null;
    this.ind2mHv2016 = obj && obj.ind_2m_hv_2016 || null;
    this.ind2mHv2017 = obj && obj.ind_2m_hv_2017 || null;
    this.ind2mHv2018 = obj && obj.ind_2m_hv_2018 || null;
    this.estTitu2018Eg2017 = obj && obj.est_titu_2018_eg_2017 || null;
    this.variacionMate2m20172016 = obj && obj.variacion_mate2m_2017_2016 || null;
    this.ind2mCc2018 = obj && obj.ind_2m_cc_2018 || null;
    this.dim2mAmMe2018 = obj && obj.dim_2m_am_me_2018 || null;
    this.dim2mPfSp2016 = obj && obj.dim_2m_pf_sp_2016 || null;
    this.casoEspecialProgreso = obj && obj.caso_especial_progreso || null;
    this.dim2mAmMe2017 = obj && obj.dim_2m_am_me_2017 || null;
    this.dim2mPfSp2017 = obj && obj.dim_2m_pf_sp_2017 || null;
    this.dim2mAmMe2016 = obj && obj.dim_2m_am_me_2016 || null;
    this.dim2mPfSp2018 = obj && obj.dim_2m_pf_sp_2018 || null;
    this.probInformacion = obj && obj.prob_informacion || null;
    this.variacionLect2m20172016 = obj && obj.variacion_lect2m_2017_2016 || null;
    this.dependencia = obj && obj.dependencia || null;
    this.promIndVs = obj && this.aproximacionValor(obj.prom_ind_vs) || null;
    this.comuna = obj && obj.comuna || null;
    this.dim2mCcAs2018 = obj && obj.dim_2m_cc_as_2018 || null;

    this.directorNombre = obj && obj.director_nombre || 'Sin Director';
    this.directorRut = obj && obj.director_rut || null;
    this.regionLargo = obj && obj.region_largo || null;

    this.promIndEdaNac = this.aproximacionValor('42.0441543340381');
    this.promIndCcNac = this.aproximacionValor('75.45597604'); /*Indicador Clima de Convivencia Escolar*/
    this.promIndPfNac = this.aproximacionValor('77.38517266'); /*Indicador Participación y Formación Ciudadana*/
    this.promIndVsNac = this.aproximacionValor('70.412463'); /*Indicador Hábitos de Vida Saludable*/
    this.promIndAsistenciaNac = this.aproximacionValor('63.75357646'); /*Indicador de Asistencia*/
    this.promIndRetencionNac = this.aproximacionValor('88.38526707'); /*Indicador de Retención Escolar*/
    this.promIndEquidadGeneroNac = this.aproximacionValor('92.13139801'); /*Indicador Equidad de Género en Aprendizajes*/
    this.promIndTitulacionTpNac = this.aproximacionValor('84.3544086');
    this.promIndTendenciaNac = this.aproximacionValor('50.65186751'); /*Indicador de Tendencia en Puntajes*/
    this.promIndPtjeSimceNac = this.aproximacionValor('50.43093728'); /*Indicador Puntaje Simce*/

    this.tieneMedia = obj && obj.tieneMedia != null ? obj.tieneMedia : null;
    this.tieneBasica = obj && obj.tieneBasica != null ? obj.tieneBasica : null;
  }

  /**
   * Metodo que se encarga de traducir el valor proveniente de la base de datos a un valor literal.
   *
   * @param categoriaDesemp string
   * @return descripcion string
   */
  getCategoriaDesempenoLiteral(categoriaDesemp: string): string {
    let descripcion: string;
    switch (categoriaDesemp) {
      case '1':
        descripcion = 'Alto';
        break;
      case '2':
        descripcion = 'Medio';
        break;
      case '3':
        descripcion = 'Medio-Bajo';
        break;
      case '4':
        descripcion = 'Insuficiente';
        break;
      case '5':
        descripcion = 'Medio-Bajo';
        break;
      case '99999':
        descripcion = null;
        break;
    }
    return descripcion;
  }

  /**
   * Metodo que se encarga de resolver llos datos missing, retornando '-' para el caso de '99999' y '*' para el caso de '88888'
   *
   * @param valor string
   * @return string
   */
  getMissingValue(valor: string): string {
    if ('99999' === valor) {
      return '-';
    } else if ('88888' === valor) {
      return '*';
    } else {
      return valor;
    }
  }

  getMissingValuePorcentaje(valor: string): string {
    const valPor = this.getMissingValue(valor);
    if ('-' === valPor || '*' === valPor) {
      return valPor;
    } else {
      return (valPor + '%');
    }
  }

  aproximacionValor(valor: string): string {
    const numero = parseFloat(valor);
    if (!isNaN(numero)) {
      return '' + Math.round(parseFloat(valor));
    } else {
      return '';
    }
  }

  getMissingValueCodigos(valor: string): string {
    if ('99999' === valor) {
      return '-';
    } else if ('88888' === valor) {
      return '*';
    } else if ('1' === valor) {
      return 'Sube';
    } else if ('0' === valor) {
      return 'Mantiene';
    } else if ('-1' === valor) {
      return 'Baja';
    } else {
      return valor;
    }
  }

  getMissingValueRangos(valor: string): string {
    if ('99999' === valor) {
      return '-';
    } else if ('88888' === valor) {
      return '*';
    } else if ('0' === valor) {
      return 'Dentro de rango';
    } else if ('-1' === valor || '1' === valor) {
      return 'Fuera de rango';
    } else {
      return valor;
    }
  }

  getMissingValueAproximado(valor: string): string {
    if ('99999' === valor) {
      return '-';
    } else if ('88888' === valor) {
      return '*';
    } else {
      return this.aproximacionValor(valor);
    }
  }

  procesarEstablecimientoMedia(data: any): any {
    let datosMat2medio: any;
    let datosLect2medio: any;

    if (data.mate2m) {
      datosMat2medio = {
        categorias: [...data.mate2m.agno],
        series: [],
        tipo: '4m'
      };
      datosMat2medio.series.push(data.mate2m.por_adecu);
      datosMat2medio.series.push(data.mate2m.por_elem);
      datosMat2medio.series.push(data.mate2m.por_insuf);
    }

    if (data.lect2m) {
      datosLect2medio = {
        categorias: [...data.lect2m.agno],
        series: [],
        tipo: '4l'
      };
      datosLect2medio.series.push(data.lect2m.por_adecu);
      datosLect2medio.series.push(data.lect2m.por_elem);
      datosLect2medio.series.push(data.lect2m.por_insuf);
    }
    return {
      datosMat2medio,
      datosLect2medio
    };
  }

}
