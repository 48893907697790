import {
  Component,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {CognitoService, LoggedInCallback} from '../../../servicios/cognito.service';

import {Constantes} from '../../../utils/Constantes';
import {TrackingService} from '../../../servicios/tracking.service';
import {Router} from '@angular/router';
import {forkJoin} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {KeycloakService} from '../../../servicios/keycloak.service';
import {DocumentosService} from '../../../servicios/documentos.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, LoggedInCallback, OnChanges {

  closeResult: string;

  @Input() rbdModel: any = {};
  @Input() enPerfil: boolean;
  @Input() tipo: any;
  @Input() perfil: number;
  @Output() abrirResumen: EventEmitter<any> = new EventEmitter();
  @Output() abriBusquedaAvanzada: EventEmitter<any> = new EventEmitter();

  public portal: string;
  // public perfil: number;
  autenticado = false;
  userData: any;

  public catInsuficiente: any = {
    url : null,
    tituloMenu: null,
    visible : false
  };
  public calculoEstandaresAp: any = {
    url : null,
    tituloMenu: null,
    visible : false
  };
  public aplicacion2020: any = {
    url : null,
    tituloMenu: null,
    visible : false
  };
  public calculoIdps: any = {
    url : null,
    tituloMenu: null,
    visible : false
  };
  public rexCatMedia: any = {
    url : null,
    tituloMenu: null,
    visible : false
  };
  public rexCatBasica: any = {
    url : null,
    tituloMenu: null,
    visible : false
  };
  public resumenApi2019: any = {
    url : null,
    tituloMenu: null,
    visible : false
  };

  public fechaDeploy: string = Constantes.FECHA_VERSION;

  constructor(private modalService: NgbModal,
              private cognitoService: CognitoService,
              public tracking: TrackingService,
              private router: Router,
              private keycloak: KeycloakService,
              private documentosService: DocumentosService) {
  }

  ngOnInit() {
    this.portal = this.router.url.split('/')[1];
    const subscribes = [];

    subscribes.push(this.documentosService.obtenerUrlDocByNombre('cat-insuficiente-2020'));
    subscribes.push(this.documentosService.obtenerUrlDocByNombre('calculo-estandares-ap'));
    subscribes.push(this.documentosService.obtenerUrlDocByNombre('aplicacion-2020'));
    subscribes.push(this.documentosService.obtenerUrlDocByNombre('calculo-IDPS'));
    subscribes.push(this.documentosService.obtenerUrlDocByNombre('REX-cat-Media'));
    subscribes.push(this.documentosService.obtenerUrlDocByNombre('REX-cat-Basica'));
    subscribes.push(this.documentosService.obtenerUrlDocByNombre('resumen-apl-2019'));

    forkJoin(subscribes).subscribe(
      (result) => {
        this.catInsuficiente = {
          url : result[0].url,
          tituloMenu : result[0].tituloMenu,
          visible : result[0].visible
        };
        this.calculoEstandaresAp = {
          url : result[1].url,
          tituloMenu : result[1].tituloMenu,
          visible : result[1].visible
        };
        this.aplicacion2020 = {
          url : result[2].url,
          tituloMenu : result[2].tituloMenu,
          visible : result[2].visible
        };
        this.calculoIdps = {
          url : result[3].url,
          tituloMenu : result[3].tituloMenu,
          visible : result[3].visible
        };
        this.rexCatMedia = {
          url : result[4].url,
          tituloMenu : result[4].tituloMenu,
          visible : result[4].visible
        };
        this.rexCatBasica = {
          url : result[5].url,
          tituloMenu : result[5].tituloMenu,
          visible : result[5].visible
        };
        this.resumenApi2019 = {
          url : result[6].url,
          tituloMenu : result[6].tituloMenu,
          visible : result[6].visible
        };
      });

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  isLoggedIn(message: string, loggedIn: boolean): void {
    if (loggedIn) {
      this.autenticado = true;
      this.userData = JSON.parse(localStorage.getItem('UserData'));
    }
  }

  logout() {
    localStorage.clear();
    const opciones = {redirectUri: 'https://agenciaeducacion.cl/'};
    this.keycloak.logout(opciones);
  }

  busquedaAvanzada() {
    this.abriBusquedaAvanzada.emit();
  }

  herramientasOrientacion() {
    this.router.navigate(['herramientas-orientacion']);
  }

  abrirLocaliza() {
    let url = environment.URL_LOCALIZA;

    if (this.rbdModel && this.rbdModel.rbd) {
      url += `?rbd=${this.rbdModel.rbd}`;
    }
    window.open(url, '_blank');
  }

  login() {
    this.keycloak.login({redirectUri : window.location.origin + '/auth'});
  }

  trackear(codigo: number): void {
    this.tracking.trackear(this.rbdModel.rbd, codigo, this.tracking.obtenerNombrePorta(this.perfil, this.tipo).id);
  }

  tabInicio() {
    this.tracking.trackear(this.rbdModel.rbd, 1003, this.tracking.obtenerNombrePorta(this.perfil, this.tipo).id);
    document.getElementById('tab-categoria-de-desempeno').click();
  }

  redireccionar() {
    this.router.navigate([('/' + this.portal + '/' + this.tipo + '/' + this.rbdModel.rbd)]);
  }
}
