import {Component, Input, OnInit} from '@angular/core';
import {RbdModel} from '../../../modelos/rbd.model';
import {TrackingService} from '../../../servicios/tracking.service';
import {PortalModel} from '../../../modelos/portal.model';

@Component({
  selector: 'app-sac-categoria',
  templateUrl: './sac-categoria.component.html',
  styleUrls: ['./sac-categoria.component.css']
})
export class SacCategoriaComponent implements OnInit {

  @Input()
  public categoriaDes2019: any;

  @Input()
  rbdModel: RbdModel = new RbdModel();

  @Input()
  tipo: string;

  @Input()
  perfil: number;

  public portalModel: PortalModel;

  constructor(public tracking: TrackingService) {
    this.portalModel = this.tracking.obtenerNombrePorta(this.perfil, this.tipo);
  }

  ngOnInit() {

  }

}
