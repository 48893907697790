/**
 * Clase RbdModel que se encarga de enmascarar el retorno de la consulta por RBD.
 */
export class RbdModel {

  public promIndCc: string;
  public variacionLect4b20172016: string;
  public eeNuevo: string;
  public dim4bCcAo2018: string;
  public dim4bCcAo2017: string;
  public dim4bCcAo2016: string;
  public brechaLect6b2015: string;
  public brechaLect6b2016: string;
  public brechaLect6b2018: string;
  public matBasica: string;
  public ind4bCc2016: string;
  public ind4bCc2017: string;
  public variacionMate6b20152014: string;
  public promLect6b2016: string;
  public dim6bCcAo2015: string;
  public ind4bCc2018: string;
  public promLect6b2015: string;
  public dim6bCcAo2018: string;
  public promLect6b2018: string;
  public dim6bCcAo2016: string;
  public deltaMate4b: string;
  public valorBrechaMate6b2018: string;
  public valorBrechaMate6b2015: string;
  public ind6bCc2015: string;
  public valorBrechaMate6b2016: string;
  public promIndPtjeSimce: string;
  public por4bAsiNormal2017: string;
  public por4bAsiNormal2018: string;
  public bajaMatricula: string;
  public ind6bAm2015: string;
  public casoEspecialProgreso: string;
  public ind6bAm2016: string;
  public por4bAsiNormal2016: string;
  public ind6bAm2018: string;
  public dim4bHvHa2016: string;
  public dim4bHvHa2017: string;
  public dim4bHvHa2018: string;
  public dim6bHvHa2018: string;
  public dim6bHvHa2015: string;
  public dim6bHvHa2016: string;
  public ind6bCc2016: string;
  public variacionLect6b20182016: string;
  public ind6bCc2018: string;
  public dim6bPfVd2018: string;
  public dim4bPfVd2018: string;
  public dim4bPfVd2017: string;
  public dim4bPfVd2016: string;
  public promMate6b2016: string;
  public promMate6b2015: string;
  public tipoEstab: string;
  public dim6bPfVd2015: string;
  public promMate6b2018: string;
  public dim6bPfVd2016: string;
  public promIndAm: string;
  public por6bAsiGrave2016: string;
  public por6bAsiGrave2015: string;
  public variacionMate4b20182017: string;
  public por6bAsiGrave2018: string;
  public dim4bPfPa2018: string;
  public dim4bPfPa2017: string;
  public dim4bPfPa2016: string;
  public dim6bPfPa2018: string;
  public brechaMate4b2016: string;
  public brechaMate4b2017: string;
  public brechaMate4b2018: string;
  public dim6bPfPa2016: string;
  public dim6bPfPa2015: string;
  public promIndEda: string;
  public promIndEdaIeEa: string;
  public rbd: string;
  public variacionLect6b20152014: string;
  public promIndPf: string;
  public ind4bEq: string;
  public dim4bAmMe2017: string;
  public dim4bAmMe2016: string;
  public dim4bAmMe2018: string;
  public dim6bAmMe2018: string;
  public nombreEstab: string;
  public dim6bAmMe2016: string;
  public dim6bAmMe2015: string;
  public promIndEdaIe: string;
  public ind6bAsi2016: string;
  public ind6bAsi2015: string;
  public ind6bAsi2018: string;
  public variacionLect6b20162015: string;
  public deltaMate6b: string;
  public brechaMate6b2018: string;
  public dependencia: string;
  public por6bAsiReiterada2018: string;
  public por6bAsiReiterada2015: string;
  public por6bAsiReiterada2016: string;
  public brechaMate6b2015: string;
  public brechaMate6b2016: string;
  public variacionLect4b20162015: string;
  public variacionMate6b20182016: string;
  public deltaLect6b: string;
  public valorBrechaMate4b2016: string;
  public ind6bHv2015: string;
  public ind6bHv2016: string;
  public ind6bHv2018: string;
  public valorBrechaMate4b2017: string;
  public valorBrechaMate4b2018: string;
  public por4bAsiReiterada2017: string;
  public por4bAsiReiterada2018: string;
  public deltaLect4b: string;
  public por4bAsiGrave2018: string;
  public comuna: string;
  public por4bAsiGrave2017: string;
  public por4bAsiGrave2016: string;
  public por4bAsiReiterada2016: string;
  public promIndAsistencia: string;
  public por6bAsiNormal2018: string;
  public por6bAsiNormal2015: string;
  public por6bAsiNormal2016: string;
  public regionCorto: string;
  public dim4bHvVa2016: string;
  public dim6bHvVa2018: string;
  public promIndEquidadGenero: string;
  public dim6bHvVa2015: string;
  public dim6bHvVa2016: string;
  public probInformacion: string;
  public dim4bCcAr2017: string;
  public dim4bCcAr2018: string;
  public categoriaDes2016: string;
  public categoriaDes2017: string;
  public categoriaDes2018: string;
  public dim4bCcAr2016: string;
  public categoriaDes2019: string;
  public categoriaDes2020: string;
  public categoriaDes2013: string;
  public categoriaDes2014: string;
  public categoriaDes2015: string;
  public promIndTendencia: string;
  public ind6bPf2018: string;
  public ind6bPf2015: string;
  public ind6bPf2016: string;
  public promIndRetencion: string;
  public porRet2016: string;
  public categoriaDesIeEa: string;
  public porRet2017: string;
  public porRet2015: string;
  public variacionLect4b20182017: string;
  public dim4bHvVa2018: string;
  public dim4bHvVa2017: string;
  public marchablBasica: string;
  public por6bAsiDestacada2018: string;
  public por6bAsiDestacada2015: string;
  public por6bAsiDestacada2016: string;
  public variacionMate4b20172016: string;
  public brechaLect4b2018: string;
  public brechaLect4b2017: string;
  public brechaLect4b2016: string;
  public valorBrechaLect6b2016: string;
  public dim6bCcAr2018: string;
  public dim6bCcAr2015: string;
  public valorBrechaLect6b2018: string;
  public dim6bCcAr2016: string;
  public ind4bAsi2017: string;
  public ind4bAsi2018: string;
  public valorBrechaLect6b2015: string;
  public ind4bPf2018: string;
  public ind4bAm2018: string;
  public ind4bPf2016: string;
  public ind4bPf2017: string;
  public ind4bAsi2016: string;
  public ind4bAm2016: string;
  public indRet2015: string;
  public ind4bAm2017: string;
  public indRet2017: string;
  public indRet2016: string;
  public promMate4b2017: string;
  public promMate4b2018: string;
  public promMate4b2016: string;
  public ind4bHv2018: string;
  public ind4bHv2016: string;
  public ind4bHv2017: string;
  public dim6bPfSp2015: string;
  public dim6bPfSp2016: string;
  public dim6bPfSp2018: string;
  public por4bAsiDestacada2018: string;
  public por4bAsiDestacada2017: string;
  public por4bAsiDestacada2016: string;
  public ind6bEq: string;
  public promLect4b2018: string;
  public promLect4b2016: string;
  public promLect4b2017: string;
  public dim4bPfSp2017: string;
  public variacionMate4b20162015: string;
  public dim4bPfSp2018: string;
  public dim4bPfSp2016: string;
  public dim6bHvAc2018: string;
  public valorBrechaLect4b2018: string;
  public promIndVs: string;
  public dim6bHvAc2015: string;
  public dim6bHvAc2016: string;
  public dim4bHvAc2018: string;
  public dim4bHvAc2016: string;
  public dim4bHvAc2017: string;
  public dim6bAmAa2015: string;
  public dim6bAmAa2016: string;
  public dim6bAmAa2018: string;
  public dim6bCcAs2018: string;
  public regionLargo: string;
  public dim4bAmAa2018: string;
  public variacionMate6b20162015: string;
  public dim6bCcAs2016: string;
  public dim4bCcAs2016: string;
  public dim4bAmAa2017: string;
  public dim4bCcAs2017: string;
  public dim4bAmAa2016: string;
  public dim4bCcAs2018: string;
  public dim6bCcAs2015: string;
  public valorBrechaLect4b2017: string;
  public valorBrechaLect4b2016: string;
  public directorNombre: string;
  public directorRut: string;

  public tieneMedia: boolean;
  public tieneBasica: boolean;


  /**
   * Conbstructor de la clase.
   *
   * @param obj
   */
  constructor(obj?: any) {
    this.promIndCc = obj && this.aproximacionValor(obj.prom_ind_cc) || null;
    this.variacionLect4b20172016 = obj && obj.variacion_lect4b_2017_2016 || null;
    this.eeNuevo = obj && obj.ee_nuevo || null;
    this.dim4bCcAo2018 = obj && obj.dim_4b_cc_ao_2018 || null;
    this.dim4bCcAo2017 = obj && obj.dim_4b_cc_ao_2017 || null;
    this.dim4bCcAo2016 = obj && obj.dim_4b_cc_ao_2016 || null;
    this.brechaLect6b2015 = obj && obj.brecha_lect6b_2015 || null;
    this.brechaLect6b2016 = obj && obj.brecha_lect6b_2016 || null;
    this.brechaLect6b2018 = obj && obj.brecha_lect6b_2018 || null;
    this.matBasica = obj && obj.mat_basica || null;
    this.ind4bCc2016 = obj && obj.ind_4b_cc_2016 || null;
    this.ind4bCc2017 = obj && obj.ind_4b_cc_2017 || null;
    this.variacionMate6b20152014 = obj && obj.variacion_mate6b_2015_2014 || null;
    this.promLect6b2016 = obj && obj.prom_lect6b_2016 || null;
    this.dim6bCcAo2015 = obj && obj.dim_6b_cc_ao_2015 || null;
    this.ind4bCc2018 = obj && obj.ind_4b_cc_2018 || null;
    this.promLect6b2015 = obj && obj.prom_lect6b_2015 || null;
    this.dim6bCcAo2018 = obj && obj.dim_6b_cc_ao_2018 || null;
    this.promLect6b2018 = obj && obj.prom_lect6b_2018 || null;
    this.dim6bCcAo2016 = obj && obj.dim_6b_cc_ao_2016 || null;
    this.deltaMate4b = obj && obj.delta_mate4b || null;
    this.valorBrechaMate6b2018 = obj && obj.valor_brecha_mate6b_2018 || null;
    this.valorBrechaMate6b2015 = obj && obj.valor_brecha_mate6b_2015 || null;
    this.ind6bCc2015 = obj && obj.ind_6b_cc_2015 || null;
    this.valorBrechaMate6b2016 = obj && obj.valor_brecha_mate6b_2016 || null;
    this.promIndPtjeSimce = obj && this.aproximacionValor(obj.prom_ind_ptje_simce) || null;
    this.por4bAsiNormal2017 = obj && obj.por_4b_asi_normal_2017 || null;
    this.por4bAsiNormal2018 = obj && obj.por_4b_asi_normal_2018 || null;
    this.bajaMatricula = obj && obj.baja_matricula || null;
    this.ind6bAm2015 = obj && obj.ind_6b_am_2015 || null;
    this.casoEspecialProgreso = obj && obj.caso_especial_progreso || null;
    this.ind6bAm2016 = obj && obj.ind_6b_am_2016 || null;
    this.por4bAsiNormal2016 = obj && obj.por_4b_asi_normal_2016 || null;
    this.ind6bAm2018 = obj && obj.ind_6b_am_2018 || null;
    this.dim4bHvHa2016 = obj && obj.dim_4b_hv_ha_2016 || null;
    this.dim4bHvHa2017 = obj && obj.dim_4b_hv_ha_2017 || null;
    this.dim4bHvHa2018 = obj && obj.dim_4b_hv_ha_2018 || null;
    this.dim6bHvHa2018 = obj && obj.dim_6b_hv_ha_2018 || null;
    this.dim6bHvHa2015 = obj && obj.dim_6b_hv_ha_2015 || null;
    this.dim6bHvHa2016 = obj && obj.dim_6b_hv_ha_2016 || null;
    this.ind6bCc2016 = obj && obj.ind_6b_cc_2016 || null;
    this.variacionLect6b20182016 = obj && obj.variacion_lect6b_2018_2016 || null;
    this.ind6bCc2018 = obj && obj.ind_6b_cc_2018 || null;
    this.dim6bPfVd2018 = obj && obj.dim_6b_pf_vd_2018 || null;
    this.dim4bPfVd2018 = obj && obj.dim_4b_pf_vd_2018 || null;
    this.dim4bPfVd2017 = obj && obj.dim_4b_pf_vd_2017 || null;
    this.dim4bPfVd2016 = obj && obj.dim_4b_pf_vd_2016 || null;
    this.promMate6b2016 = obj && obj.prom_mate6b_2016 || null;
    this.promMate6b2015 = obj && obj.prom_mate6b_2015 || null;
    this.tipoEstab = obj && obj.tipo_estab || null;
    this.dim6bPfVd2015 = obj && obj.dim_6b_pf_vd_2015 || null;
    this.promMate6b2018 = obj && obj.prom_mate6b_2018 || null;
    this.dim6bPfVd2016 = obj && obj.dim_6b_pf_vd_2016 || null;
    this.promIndAm = obj && this.aproximacionValor(obj.prom_ind_am) || null;
    this.por6bAsiGrave2016 = obj && obj.por_6b_asi_grave_2016 || null;
    this.por6bAsiGrave2015 = obj && obj.por_6b_asi_grave_2015 || null;
    this.variacionMate4b20182017 = obj && obj.variacion_mate4b_2018_2017 || null;
    this.por6bAsiGrave2018 = obj && obj.por_6b_asi_grave_2018 || null;
    this.dim4bPfPa2018 = obj && obj.dim_4b_pf_pa_2018 || null;
    this.dim4bPfPa2017 = obj && obj.dim_4b_pf_pa_2017 || null;
    this.dim4bPfPa2016 = obj && obj.dim_4b_pf_pa_2016 || null;
    this.dim6bPfPa2018 = obj && obj.dim_6b_pf_pa_2018 || null;
    this.brechaMate4b2016 = obj && obj.brecha_mate4b_2016 || null;
    this.brechaMate4b2017 = obj && obj.brecha_mate4b_2017 || null;
    this.brechaMate4b2018 = obj && obj.brecha_mate4b_2018 || null;
    this.dim6bPfPa2016 = obj && obj.dim_6b_pf_pa_2016 || null;
    this.dim6bPfPa2015 = obj && obj.dim_6b_pf_pa_2015 || null;
    this.promIndEda = obj && this.aproximacionValor(obj.prom_ind_eda) || null;
    this.promIndEdaIeEa = obj && this.aproximacionValor(obj.prom_ind_eda_ie_ea) || null;
    this.rbd = obj && obj.rbd || null;
    this.variacionLect6b20152014 = obj && obj.variacion_lect6b_2015_2014 || null;
    this.promIndPf = obj && this.aproximacionValor(obj.prom_ind_pf) || null;
    this.ind4bEq = obj && obj.ind_4b_eq || null;
    this.dim4bAmMe2017 = obj && obj.dim_4b_am_me_2017 || null;
    this.dim4bAmMe2016 = obj && obj.dim_4b_am_me_2016 || null;
    this.dim4bAmMe2018 = obj && obj.dim_4b_am_me_2018 || null;
    this.dim6bAmMe2018 = obj && obj.dim_6b_am_me_2018 || null;
    this.nombreEstab = obj && obj.nombre_estab || null;
    this.dim6bAmMe2016 = obj && obj.dim_6b_am_me_2016 || null;
    this.dim6bAmMe2015 = obj && obj.dim_6b_am_me_2015 || null;
    this.promIndEdaIe = obj && this.aproximacionValor(obj.prom_ind_eda_ie) || null;
    this.ind6bAsi2016 = obj && obj.ind_6b_asi_2016 || null;
    this.ind6bAsi2015 = obj && obj.ind_6b_asi_2015 || null;
    this.ind6bAsi2018 = obj && obj.ind_6b_asi_2018 || null;
    this.variacionLect6b20162015 = obj && obj.variacion_lect6b_2016_2015 || null;
    this.deltaMate6b = obj && obj.delta_mate6b || null;
    this.brechaMate6b2018 = obj && obj.brecha_mate6b_2018 || null;
    this.dependencia = obj && obj.dependencia || null;
    this.por6bAsiReiterada2018 = obj && obj.por_6b_asi_reiterada_2018 || null;
    this.por6bAsiReiterada2015 = obj && obj.por_6b_asi_reiterada_2015 || null;
    this.por6bAsiReiterada2016 = obj && obj.por_6b_asi_reiterada_2016 || null;
    this.brechaMate6b2015 = obj && obj.brecha_mate6b_2015 || null;
    this.brechaMate6b2016 = obj && obj.brecha_mate6b_2016 || null;
    this.variacionLect4b20162015 = obj && obj.variacion_lect4b_2016_2015 || null;
    this.variacionMate6b20182016 = obj && obj.variacion_mate6b_2018_2016 || null;
    this.deltaLect6b = obj && obj.delta_lect6b || null;
    this.valorBrechaMate4b2016 = obj && obj.valor_brecha_mate4b_2016 || null;
    this.ind6bHv2015 = obj && obj.ind_6b_hv_2015 || null;
    this.ind6bHv2016 = obj && obj.ind_6b_hv_2016 || null;
    this.ind6bHv2018 = obj && obj.ind_6b_hv_2018 || null;
    this.valorBrechaMate4b2017 = obj && obj.valor_brecha_mate4b_2017 || null;
    this.valorBrechaMate4b2018 = obj && obj.valor_brecha_mate4b_2018 || null;
    this.por4bAsiReiterada2017 = obj && obj.por_4b_asi_reiterada_2017 || null;
    this.por4bAsiReiterada2018 = obj && obj.por_4b_asi_reiterada_2018 || null;
    this.deltaLect4b = obj && obj.delta_lect4b || null;
    this.por4bAsiGrave2018 = obj && obj.por_4b_asi_grave_2018 || null;
    this.comuna = obj && obj.comuna || null;
    this.por4bAsiGrave2017 = obj && obj.por_4b_asi_grave_2017 || null;
    this.por4bAsiGrave2016 = obj && obj.por_4b_asi_grave_2016 || null;
    this.por4bAsiReiterada2016 = obj && obj.por_4b_asi_reiterada_2016 || null;
    this.promIndAsistencia = obj && this.aproximacionValor(obj.prom_ind_asistencia) || null;
    this.por6bAsiNormal2018 = obj && obj.por_6b_asi_normal_2018 || null;
    this.por6bAsiNormal2015 = obj && obj.por_6b_asi_normal_2015 || null;
    this.por6bAsiNormal2016 = obj && obj.por_6b_asi_normal_2016 || null;
    this.regionCorto = obj && obj.region_corto || null;
    this.dim4bHvVa2016 = obj && obj.dim_4b_hv_va_2016 || null;
    this.dim6bHvVa2018 = obj && obj.dim_6b_hv_va_2018 || null;
    this.promIndEquidadGenero = obj && this.aproximacionValor(obj.prom_ind_equidad_genero) || null;
    this.dim6bHvVa2015 = obj && obj.dim_6b_hv_va_2015 || null;
    this.dim6bHvVa2016 = obj && obj.dim_6b_hv_va_2016 || null;
    this.probInformacion = obj && obj.prob_informacion || null;
    this.dim4bCcAr2017 = obj && obj.dim_4b_cc_ar_2017 || null;
    this.dim4bCcAr2018 = obj && obj.dim_4b_cc_ar_2018 || null;
    this.categoriaDes2016 = obj && obj.categoria_des2016 || null;
    this.categoriaDes2017 = obj && obj.categoria_des2017 || null;
    this.categoriaDes2018 = obj && obj.categoria_des2018 || null;
    this.categoriaDes2019 = obj && obj.categoria_des2019 || null;
    this.categoriaDes2020 = obj && obj.categoria_des2020 || null;
    this.categoriaDes2013 = obj && obj.categoria_des2013 || null;
    this.categoriaDes2014 = obj && obj.categoria_des2014 || null;
    this.categoriaDes2015 = obj && obj.categoria_des2015 || null;
    this.dim4bCcAr2016 = obj && obj.dim_4b_cc_ar_2016 || null;
    this.promIndTendencia = obj && this.aproximacionValor(obj.prom_ind_tendencia) || null;
    this.ind6bPf2018 = obj && obj.ind_6b_pf_2018 || null;
    this.ind6bPf2015 = obj && obj.ind_6b_pf_2015 || null;
    this.ind6bPf2016 = obj && obj.ind_6b_pf_2016 || null;
    this.promIndRetencion = obj && this.aproximacionValor(obj.prom_ind_retencion) || null;
    this.porRet2016 = obj && obj.por_ret_2016 || null;
    this.categoriaDesIeEa = obj && obj.categoria_des_ie_ea || null;
    this.porRet2017 = obj && obj.por_ret_2017 || null;
    this.porRet2015 = obj && obj.por_ret_2015 || null;
    this.variacionLect4b20182017 = obj && obj.variacion_lect4b_2018_2017 || null;
    this.dim4bHvVa2018 = obj && obj.dim_4b_hv_va_2018 || null;
    this.dim4bHvVa2017 = obj && obj.dim_4b_hv_va_2017 || null;
    this.marchablBasica = obj && obj.marchabl_basica || null;
    this.por6bAsiDestacada2018 = obj && obj.por_6b_asi_destacada_2018 || null;
    this.por6bAsiDestacada2015 = obj && obj.por_6b_asi_destacada_2015 || null;
    this.por6bAsiDestacada2016 = obj && obj.por_6b_asi_destacada_2016 || null;
    this.variacionMate4b20172016 = obj && obj.variacion_mate4b_2017_2016 || null;
    this.brechaLect4b2018 = obj && obj.brecha_lect4b_2018 || null;
    this.brechaLect4b2017 = obj && obj.brecha_lect4b_2017 || null;
    this.brechaLect4b2016 = obj && obj.brecha_lect4b_2016 || null;
    this.valorBrechaLect6b2016 = obj && obj.valor_brecha_lect6b_2016 || null;
    this.dim6bCcAr2018 = obj && obj.dim_6b_cc_ar_2018 || null;
    this.dim6bCcAr2015 = obj && obj.dim_6b_cc_ar_2015 || null;
    this.valorBrechaLect6b2018 = obj && obj.valor_brecha_lect6b_2018 || null;
    this.dim6bCcAr2016 = obj && obj.dim_6b_cc_ar_2016 || null;
    this.ind4bAsi2017 = obj && obj.ind_4b_asi_2017 || null;
    this.ind4bAsi2018 = obj && obj.ind_4b_asi_2018 || null;
    this.valorBrechaLect6b2015 = obj && obj.valor_brecha_lect6b_2015 || null;
    this.ind4bPf2018 = obj && obj.ind_4b_pf_2018 || null;
    this.ind4bAm2018 = obj && obj.ind_4b_am_2018 || null;
    this.ind4bPf2016 = obj && obj.ind_4b_pf_2016 || null;
    this.ind4bPf2017 = obj && obj.ind_4b_pf_2017 || null;
    this.ind4bAsi2016 = obj && obj.ind_4b_asi_2016 || null;
    this.ind4bAm2016 = obj && obj.ind_4b_am_2016 || null;
    this.indRet2015 = obj && obj.ind_ret_2015 || null;
    this.ind4bAm2017 = obj && obj.ind_4b_am_2017 || null;
    this.indRet2017 = obj && obj.ind_ret_2017 || null;
    this.indRet2016 = obj && obj.ind_ret_2016 || null;
    this.promMate4b2017 = obj && obj.prom_mate4b_2017 || null;
    this.promMate4b2018 = obj && obj.prom_mate4b_2018 || null;
    this.promMate4b2016 = obj && obj.prom_mate4b_2016 || null;
    this.ind4bHv2018 = obj && obj.ind_4b_hv_2018 || null;
    this.ind4bHv2016 = obj && obj.ind_4b_hv_2016 || null;
    this.ind4bHv2017 = obj && obj.ind_4b_hv_2017 || null;
    this.dim6bPfSp2015 = obj && obj.dim_6b_pf_sp_2015 || null;
    this.dim6bPfSp2016 = obj && obj.dim_6b_pf_sp_2016 || null;
    this.dim6bPfSp2018 = obj && obj.dim_6b_pf_sp_2018 || null;
    this.por4bAsiDestacada2018 = obj && obj.por_4b_asi_destacada_2018 || null;
    this.por4bAsiDestacada2017 = obj && obj.por_4b_asi_destacada_2017 || null;
    this.por4bAsiDestacada2016 = obj && obj.por_4b_asi_destacada_2016 || null;
    this.ind6bEq = obj && obj.ind_6b_eq || null;
    this.promLect4b2018 = obj && obj.prom_lect4b_2018 || null;
    this.promLect4b2016 = obj && obj.prom_lect4b_2016 || null;
    this.promLect4b2017 = obj && obj.prom_lect4b_2017 || null;
    this.dim4bPfSp2017 = obj && obj.dim_4b_pf_sp_2017 || null;
    this.variacionMate4b20162015 = obj && obj.variacion_mate4b_2016_2015 || null;
    this.dim4bPfSp2018 = obj && obj.dim_4b_pf_sp_2018 || null;
    this.dim4bPfSp2016 = obj && obj.dim_4b_pf_sp_2016 || null;
    this.dim6bHvAc2018 = obj && obj.dim_6b_hv_ac_2018 || null;
    this.valorBrechaLect4b2018 = obj && obj.valor_brecha_lect4b_2018 || null;
    this.promIndVs = obj && this.aproximacionValor(obj.prom_ind_vs) || null;
    this.dim6bHvAc2015 = obj && obj.dim_6b_hv_ac_2015 || null;
    this.dim6bHvAc2016 = obj && obj.dim_6b_hv_ac_2016 || null;
    this.dim4bHvAc2018 = obj && obj.dim_4b_hv_ac_2018 || null;
    this.dim4bHvAc2016 = obj && obj.dim_4b_hv_ac_2016 || null;
    this.dim4bHvAc2017 = obj && obj.dim_4b_hv_ac_2017 || null;
    this.dim6bAmAa2015 = obj && obj.dim_6b_am_aa_2015 || null;
    this.dim6bAmAa2016 = obj && obj.dim_6b_am_aa_2016 || null;
    this.dim6bAmAa2018 = obj && obj.dim_6b_am_aa_2018 || null;
    this.dim6bCcAs2018 = obj && obj.dim_6b_cc_as_2018 || null;
    this.regionLargo = obj && obj.region_largo || null;
    this.dim4bAmAa2018 = obj && obj.dim_4b_am_aa_2018 || null;
    this.variacionMate6b20162015 = obj && obj.variacion_mate6b_2016_2015 || null;
    this.dim6bCcAs2016 = obj && obj.dim_6b_cc_as_2016 || null;
    this.dim4bCcAs2016 = obj && obj.dim_4b_cc_as_2016 || null;
    this.dim4bAmAa2017 = obj && obj.dim_4b_am_aa_2017 || null;
    this.dim4bCcAs2017 = obj && obj.dim_4b_cc_as_2017 || null;
    this.dim4bAmAa2016 = obj && obj.dim_4b_am_aa_2016 || null;
    this.dim4bCcAs2018 = obj && obj.dim_4b_cc_as_2018 || null;
    this.dim6bCcAs2015 = obj && obj.dim_6b_cc_as_2015 || null;
    this.valorBrechaLect4b2017 = obj && obj.valor_brecha_lect4b_2017 || null;
    this.valorBrechaLect4b2016 = obj && obj.valor_brecha_lect4b_2016 || null;
    this.directorNombre = obj && obj.director_nombre || 'Sin Director';
    this.directorRut = obj && obj.director_rut || null;

    this.tieneMedia = obj && obj.tieneMedia != null ? obj.tieneMedia : null;
    this.tieneBasica = obj && obj.tieneBasica != null ? obj.tieneBasica : null;
  }

  /**
   * Metodo que se encarga de traducir el valor proveniente de la base de datos a un valor literal.
   *
   * @param categoriaDesemp string
   * @return descripcion string
   */
  getCategoriaDesempenoLiteral(categoriaDesemp: string): string {
    let descripcion: string;
    switch (categoriaDesemp) {
      case '1':
        descripcion = 'Alto';
        break;
      case '2':
        descripcion = 'Medio';
        break;
      case '3':
        descripcion = 'Medio-Bajo';
        break;
      case '4':
        descripcion = 'Insuficiente';
        break;
      case '5':
        descripcion = 'Medio-Bajo';
        break;
      case '99999':
        descripcion = null;
        break;
    }
    return descripcion;
  }

  /**
   * Metodo que se encarga de resolver llos datos missing, retornando '-' para el caso de '99999' y '*' para el caso de '88888'
   *
   * @param valor string
   * @return string
   */
  getMissingValue(valor: string): string {
    if ('99999' === valor) {
      return '-';
    } else if ('88888' === valor) {
      return '*';
    } else {
      return valor;
    }
  }

  getMissingValuePorcentaje(valor: string): string {
    const valPor = this.getMissingValue(valor);
    if ('-' === valPor || '*' === valPor) {
      return valPor;
    } else {
      return (valPor + '%');
    }
  }

  getMissingValueAproximado(valor: string): string {
    if ('99999' === valor) {
      return '-';
    } else if ('88888' === valor) {
      return '*';
    } else {
      return this.aproximacionValor(valor);
    }
  }

  aproximacionValor(valor: string): string {
    const numero = parseFloat(valor);
    if (!isNaN(numero)) {
      return '' + Math.round(parseFloat(valor));
    } else {
      return '';
    }
  }

  getMissingValueCodigos(valor: string): string {
    if ('99999' === valor) {
      return '-';
    } else if ('88888' === valor) {
      return '*';
    } else if ('1' === valor) {
      return 'Sube';
    } else if ('0' === valor) {
      return 'Mantiene';
    } else if ('-1' === valor) {
      return 'Baja';
    } else {
      return valor;
    }
  }

  getMissingValueRangos(valor: string): string {
    if ('99999' === valor) {
      return '-';
    } else if ('88888' === valor) {
      return '*';
    } else if ('0' === valor) {
      return 'Dentro de rango';
    } else if ('-1' === valor || '1' === valor) {
      return 'Fuera de rango';
    } else {
      return valor;
    }
  }

  procesarEstablecimientoBasica(data: any): any {
    let datosMat4basico: any;
    let datosMat6basico: any;
    let datosLect4basico: any;
    let datosLect6basico: any;

    if (data.mate4b) {
      datosMat4basico = {
        categorias: [...data.mate4b.agno],
        series: [],
        tipo: '4m'
      };
      datosMat4basico.series.push(data.mate4b.por_adecu);
      datosMat4basico.series.push(data.mate4b.por_elem);
      datosMat4basico.series.push(data.mate4b.por_insuf);
    }

    if (data.mate6b) {
      datosMat6basico = {
        categorias: [...data.mate6b.agno],
        series: [],
        tipo: '6m'
      };
      datosMat6basico.series.push(data.mate6b.por_adecu);
      datosMat6basico.series.push(data.mate6b.por_elem);
      datosMat6basico.series.push(data.mate6b.por_insuf);
    }

    if (data.lect4b) {
      datosLect4basico = {
        categorias: [...data.lect4b.agno],
        series: [],
        tipo: '4l'
      };
      datosLect4basico.series.push(data.lect4b.por_adecu);
      datosLect4basico.series.push(data.lect4b.por_elem);
      datosLect4basico.series.push(data.lect4b.por_insuf);
    }

    if (data.lect6b) {
      datosLect6basico = {
        categorias: [...data.lect6b.agno],
        series: [],
        tipo: '6l'
      };
      datosLect6basico.series.push(data.lect6b.por_adecu);
      datosLect6basico.series.push(data.lect6b.por_elem);
      datosLect6basico.series.push(data.lect6b.por_insuf);
    }
    return {
      datosMat4basico,
      datosMat6basico,
      datosLect4basico,
      datosLect6basico,
    };
  }
}
