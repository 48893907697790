export class RbdSimpleModel {

  rbd: number;
  nombre: string;
  nombreCompleto: string;
  tieneMedia: boolean;
  tieneBasica: boolean;

  categoriaDesMedia: string;
  categoriaDesBasica: string;

  constructor(obj?: any) {
    this.rbd = obj && obj.rbd || null;
    this.nombre = obj && obj.nombre_estab || null;

    this.tieneMedia = !!(obj && obj.media);
    this.tieneBasica = !!(obj && obj.basica);

    this.categoriaDesMedia = obj && obj.media && obj.media.categoria_des2019 || null;
    this.categoriaDesBasica = obj && obj.basica && obj.basica.categoria_des2019 || null;

    if (this.rbd && this.nombre) {
      this.nombreCompleto = `${this.rbd} - ${this.nombre}`;
    }
  }
}
