import {Component, OnInit, ViewChild} from '@angular/core';
import {trigger, style, animate, transition} from '@angular/animations';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {forkJoin} from 'rxjs';
import {RbdModel} from '../../../../modelos/rbd.model';
import {DocumentosService} from '../../../../servicios/documentos.service';
import {RbdService} from '../../../../servicios/rbd.service';
import {TrackingService} from '../../../../servicios/tracking.service';
import {LoginModel} from '../../../../modelos/login.model';
import {HeaderComponent} from '../../../commons/header/header.component';
import {PortalModel} from '../../../../modelos/portal.model';
import {KeycloakService} from '../../../../servicios/keycloak.service';

@Component({
  selector: 'app-director-basica',
  templateUrl: './director-basica.component.html',
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(-10%)', opacity: 0}),
          animate('200ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('200ms', style({transform: 'translateY(-10%)', opacity: 1}))
        ])
      ]
    )
  ],
  styleUrls: ['./director-basica.component.css']
})
export class DirectorBasicaComponent implements OnInit {

  @ViewChild('header', null)
  headerComponent: HeaderComponent;

  public rbd: string;
  public rbdModel: RbdModel = new RbdModel();

  public loginModel: LoginModel = new LoginModel();
  public closeResult: string;

  public datosMat4basico: any;
  public datosMat6basico: any;
  public datosLect4basico: any;
  public datosLect6basico: any;

  public portalId = 5;

  p4bLect: string;
  tab4bLect: number;

  p6bLect: string;
  tab6bLect: number;

  p4bMat: string;
  tab4bMat: number;

  p6bMat: string;
  tab6bMat: number;

  url2016: SafeResourceUrl;
  url2017: SafeResourceUrl;
  url2018: SafeResourceUrl;

  url2019;

  show;
  show2;
  show3;

  public portalModel: PortalModel;
  public urlIndicador: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private rbdService: RbdService,
              private modalService: NgbModal,
              public tracking: TrackingService,
              private activeModal: NgbActiveModal,
              private documentosService: DocumentosService,
              private domSanitizer: DomSanitizer,
              private ngxService: NgxUiLoaderService,
              private keycloakService: KeycloakService) {
    this.route.params.subscribe((pathParams) => {
      if (pathParams.rbd) {
        this.keycloakService.validarAccesoRbd(pathParams.rbd);
      }
    });
  }

  ngOnInit() {
    this.tab4bLect = -1;
    this.tab6bLect = -1;
    this.tab4bMat = -1;
    this.tab6bMat = -1;

    const subscribes = [];
    subscribes.push(this.documentosService.obtenerUrlDocByNombre('MetConstruccionIDPS-dic-2016'));

    forkJoin(subscribes).subscribe(
      (result) => {
        this.urlIndicador = result[0].url;
      });
    this.route.params.subscribe((pathParams) => {
      if (pathParams.rbd) {
        this.rbd = pathParams.rbd;
        this.loginModel.perfil = 1;
        this.obtnerRbd(this.rbd);
        this.portalModel = this.tracking.obtenerNombrePorta(this.loginModel.perfil, 'basica');
      }
    });

  }

  /**
   * Metodo que se encarga de obtener los datos segun el RBD
   *
   * @param rbd
   */
  obtnerRbd(rbd: string): void {
    this.ngxService.start();
    const subscribes = [];
    subscribes.push(this.rbdService.buscar2('basica', rbd));
    subscribes.push(this.rbdService.anioPrueba(rbd));
    forkJoin(subscribes).subscribe(
      (result) => {
        const establecimiento = this.rbdModel.procesarEstablecimientoBasica(result[1]);
        this.datosMat4basico = establecimiento.datosMat4basico;
        this.datosMat6basico = establecimiento.datosMat6basico;
        this.datosLect4basico = establecimiento.datosLect4basico;
        this.datosLect6basico = establecimiento.datosLect6basico;

        this.rbdModel = new RbdModel(result[0]);

        this.ngxService.stop();
      }, error => {
        this.ngxService.stop();
        console.log(error);
        if (error.status === 404) {
          alert('Estimado usuario el RBD consultado no existe.');
          window.location.href = 'https://agenciaeducacion.cl/';
        }
      });
  }

  irPortal(tipo: string) {
    this.router.navigate(['/docente/' + tipo + '/' + this.rbd]);
  }

  seleccionarTab(event: any) {
    switch (event.tipo) {
      case '4l':
        this.p4bLect = event.porcentajeSeleccionado;
        this.tab4bLect = event.tabSeleccionado;
        break;
      case '6l':
        this.p6bLect = event.porcentajeSeleccionado;
        this.tab6bLect = event.tabSeleccionado;
        break;
      case '4m':
        this.p4bMat = event.porcentajeSeleccionado;
        this.tab4bMat = event.tabSeleccionado;
        break;
      case '6m':
        this.p6bMat = event.porcentajeSeleccionado;
        this.tab6bMat = event.tabSeleccionado;
        break;
    }
  }

  abrirLink(link: string) {
    window.open(link, '_blank');
  }

}
