import {Component, HostListener, OnDestroy, EventEmitter} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Constantes} from './utils/Constantes';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EncuestaService} from './servicios/encuesta.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Categoría de Desempeño';

  public closeResult: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private modalService: NgbModal,
              public encuestaService: EncuestaService) {
  }

  irPortal(tipo: string) {
    let urlDestino: string;
    if (tipo === 'basica') {
      urlDestino = this.router.url.replace(tipo, 'media');
    } else {
      urlDestino = this.router.url.replace(tipo, 'basica');
    }
    /*this.router.navigate(['/' + tipo + '/' + this.rbd]);*/
    this.router.navigate([urlDestino]);
  }

  async openDialog(): Promise<any> {
    const modalRef = this.modalService.open(Constantes.MODALS[Constantes.ENCUESTA_MODAL], {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    await modalRef.result.then((result) => {
      console.log(result);
      this.closeResult = `Closed with: ${result}`;
       // this.encuestaService.registrarAsync(result);
      return Promise.resolve(true);
      // this.kcService.logout({redirectUri: 'https://agenciaeducacion.cl/'});
    }, (reason) => {
      console.log(reason);
      this.closeResult = `Dismissed ${Constantes.getDismissReason(reason)}`;
      return Promise.resolve(false);
    });
  }

  /*@HostListener('window:beforeunload')
  async ngOnDestroy() {

    const result = await this.openDialog(); // waiting here
    console.log('result', result);
    /!*const modalRef = this.modalService.open(Constantes.MODALS[Constantes.ENCUESTA_MODAL], {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    await modalRef.result.then((result) => {
      console.log(result);
      this.closeResult = `Closed with: ${result}`;
      return this.encuestaService.registrarAsync(result);
      // this.kcService.logout({redirectUri: 'https://agenciaeducacion.cl/'});
    }, (reason) => {
      console.log(reason);
      this.closeResult = `Dismissed ${Constantes.getDismissReason(reason)}`;
    });*!/

    // await alert('cerrando ventana');

  }*/

  // @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    alert('alerta');
    /*event.preventDefault();
    event.returnValue = '';*/
    this.openDocumentSaveDialog();

    // return false;
    // I have used return false but you can your other functions or any query or condition
  };
  public openDocumentSaveDialog(): void {
    const modalRef = this.modalService.open(Constantes.MODALS[Constantes.ENCUESTA_MODAL], {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      return true;
      // return this.encuestaService.registrarAsync(result);
      // this.kcService.logout({redirectUri: 'https://agenciaeducacion.cl/'});
    }, (reason) => {
      this.closeResult = `Dismissed ${Constantes.getDismissReason(reason)}`;
      return false;
    });
    // return resultado;
  }


}
