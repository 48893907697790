import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

import {Chart} from 'angular-highcharts';

/**
 * Componente encargado de generar un grafico de barra.
 */
@Component({
  selector: 'app-grafico-barra',
  templateUrl: './barra.component.html',
  styleUrls: ['./barra.component.css']
})
export class BarraComponent implements OnInit, OnChanges {

  @Input() public datos: any;
  @Input() public anios: any;
  @Input() public config: any;
  @Output() seleccionarTab: EventEmitter<any> = new EventEmitter();

  public chart: Chart;
  tipo = '';


  constructor() {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('config') && this.config && this.config.categorias && this.config.series) {
      this.tipo = this.config.tipo;
      this.initIndividual(this.config.categorias, this.config.series);
    }
  }

  /**
   * Metodo que se encarga de configurar el grafico a desplegar
   * @param categorias Array de String
   * @param series Objeto
   */
  initIndividual(categorias, series) {
    if (!this.chart) {
      this.chart = new Chart({
        chart: {
          type: 'column'
        },
        legend: {
          enabled: false
        },
        colors: ['#244b84', '#2f60c0', '#78a8ea'],
        title: {
          text: null
        },
        xAxis: {
          categories: categorias
        },
        yAxis: {
          title: {
            text: null
          }
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          column: {
            stacking: 'percent',
            dataLabels: {
              enabled: true,
              formatter() {
                if (this.y !== 0) {
                  return this.y + '%';
                } else {
                  return null;
                }
              }
            },
            pointWidth: 125
          },
          series: {
            cursor: 'pointer',
            point: {
              events: {
                mouseOver: (event: Event) => {
                  this.seleccionarTab.emit({
                    // @ts-ignore
                    tabSeleccionado: event.target.series.index,
                    // @ts-ignore
                    porcentajeSeleccionado: event.target.y,
                    tipo: this.tipo
                  });
                },
                mouseOut: () => {
                  this.seleccionarTab.emit({
                    tabSeleccionado: -1,
                    porcentajeSeleccionado: '',
                    tipo: this.tipo
                  });
                }
              }
            }
          }
        },
        tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}%</b><br/>',
          shared: false
        },
        series
      });
    }
  }

}
