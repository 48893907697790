import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DirectorBasicaComponent} from './componentes/vista/director/director-basica/director-basica.component';
import {DirectorMediaComponent} from './componentes/vista/director/director-media/director-media.component';
import {AutoridadBasicaComponent} from './componentes/vista/autoridad/autoridad-basica/autoridad-basica.component';
import {AutoridadMediaComponent} from './componentes/vista/autoridad/autoridad-media/autoridad-media.component';
import {ApoderadoBasicaComponent} from './componentes/vista/apoderado/apoderado-basica/apoderado-basica.component';
import {ApoderadoMediaComponent} from './componentes/vista/apoderado/apoderado-media/apoderado-media.component';
import {EstudianteMediaComponent} from './componentes/vista/estudiante/estudiante-media/estudiante-media.component';
import {EstudianteBasicaComponent} from './componentes/vista/estudiante/estudiante-basica/estudiante-basica.component';
import {DocenteBasicaComponent} from './componentes/vista/docente/docente-basica/docente-basica.component';
import {DocenteMediaComponent} from './componentes/vista/docente/docente-media/docente-media.component';
import {AgenciaBasicaComponent} from './componentes/vista/agencia/agencia-basica/agencia-basica.component';
import {AgenciaMediaComponent} from './componentes/vista/agencia/agencia-media/agencia-media.component';
import {OtrosBasicaComponent} from './componentes/vista/otros/otros-basica/otros-basica.component';
import {OtrosMediaComponent} from './componentes/vista/otros/otros-media/otros-media.component';

import {AuthComponent} from './componentes/auth/auth.component';
import {LoginComponent} from './componentes/login/login.component';
import {RouteGuardService} from './servicios/route-guard.service';
import {MineducBasicaComponent} from "./componentes/vista/mineduc/mineduc-basica/mineduc-basica.component";
import {MineducMediaComponent} from "./componentes/vista/mineduc/mineduc-media/mineduc-media.component";


const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  /*  { path: 'login', component: LoginComponent },
    { path: 'inicio', component: InicioComponent },
    { path: 'inicio/:rbd', component: InicioComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'dashboard/:rbd', component: DashboardComponent },
    { path: 'test', component: TestComponent },*/
  {
    path: 'director/basica/:rbd', component: DirectorBasicaComponent,
    data: {
      perfil: 'director'
    },
    canActivate: [RouteGuardService]
  },
  {
    path: 'director/media/:rbd', component: DirectorMediaComponent,
    data: {
      perfil: 'director'
    },
    canActivate: [RouteGuardService]
  },
  {
    path: 'autoridad/basica/:rbd', component: AutoridadBasicaComponent,
    data: {
      perfil: 'autoridad'
    }
  },
  {
    path: 'autoridad/media/:rbd', component: AutoridadMediaComponent,
    data: {
      perfil: 'autoridad'
    }
  },
  {
    path: 'autoridad/basica', component: AutoridadBasicaComponent,
    data: {
      perfil: 'autoridad'
    },
    canActivate: [RouteGuardService]
  },
  {
    path: 'autoridad/media', component: AutoridadMediaComponent,
    data: {
      perfil: 'autoridad'
    },
    canActivate: [RouteGuardService]
  },
  {
    path: 'mineduc/basica/:rbd', component: MineducBasicaComponent,
    data: {
      perfil: 'mineduc'
    }
  },
  {
    path: 'mineduc/media/:rbd', component: MineducMediaComponent,
    data: {
      perfil: 'mineduc'
    }
  },
  {
    path: 'mineduc/basica', component: MineducBasicaComponent,
    data: {
      perfil: 'mineduc'
    },
    canActivate: [RouteGuardService]
  },
  {
    path: 'mineduc/media', component: MineducMediaComponent,
    data: {
      perfil: 'mineduc'
    },
    canActivate: [RouteGuardService]
  },
  {
    path: 'docente/basica/:rbd',
    component: DocenteBasicaComponent,
    data: {
      perfil: 'docente'
    }, canActivate: [RouteGuardService]
  },
  {
    path: 'docente/media/:rbd', component: DocenteMediaComponent,
    data: {
      perfil: 'docente'
    },
    canActivate: [RouteGuardService]
  },
  {
    path: 'apoderado/basica/:rbd', component: ApoderadoBasicaComponent,
    data: {
      perfil: 'apoderado'
    }
  },
  {
    path: 'apoderado/media/:rbd', component: ApoderadoMediaComponent,
    data: {
      perfil: 'apoderado'
    }
  },
  {
    path: 'estudiante/basica/:rbd', component: EstudianteBasicaComponent,
    data: {
      perfil: 'estudiante'
    }
  },
  {
    path: 'estudiante/media/:rbd', component: EstudianteMediaComponent,
    data: {
      perfil: 'estudiante'
    }
  },
  {
    path: 'agencia/basica/:rbd', component: AgenciaBasicaComponent,
    data: {
      perfil: 'agencia'
    }
  },
  {
    path: 'agencia/media/:rbd', component: AgenciaMediaComponent,
    data: {
      perfil: 'agencia'
    }
  },
  {
    path: 'otros/basica/:rbd', component: OtrosBasicaComponent,
    data: {
      perfil: 'otros'
    }
  },
  {
    path: 'otros/media/:rbd', component: OtrosMediaComponent,
    data: {
      perfil: 'otros'
    }

  },
  {path: 'auth/:params/:tipo', component: AuthComponent},
  {path: 'auth/:tipo', component: AuthComponent},
  /*  { path: 'herramientas-orientacion', component: HerramientasOrientacionComponent },
     { path: 'resultados-generales/:nivel', component: ResultadosGeneralesComponent },*/
  {path: '**', component: LoginComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'ignore'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
