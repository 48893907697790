import {RbdResultadoGeneralBasicaModel} from './rbd-resultado-general-basica.model';

export class ItmeResultadoGeneralModel {
  public LastEvaluatedKey: any;
  public totalElementos: number;
  public Items: RbdResultadoGeneralBasicaModel[];

  constructor(obj?: any) {
    this.LastEvaluatedKey = obj && obj.LastEvaluatedKey || null;
    this.totalElementos = obj && obj.totalElementos || null;
    this.Items = obj && obj.Items || null;
  }
}
