export class PortalModel {
  id: number;
  nombre: string;
  portal: string;
  nombreLargo: string;
  nombreTipoPortal: string;

  constructor(obj?: any) {
    this.id = obj && obj.id || null;
    this.nombre = obj && obj.nombre || null;
    this.portal = obj && obj.portal || null;
    this.nombreLargo = obj && obj.nombreLargo || null;
    this.nombreTipoPortal = obj && obj.nombreTipoPortal || null;
  }
}
