import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {PortalModel} from '../modelos/portal.model';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  PORTAL = {
    portal1: 'Director Básica',
    portal2: 'Director Media',
    portal3: 'Sostenedor/Autoridad Intermedia Básica',
    portal4: 'Sostenedor/Autoridad Intermedia Media',
    portal5: 'Docente Básica',
    portal6: 'Apoderados Básica',
    portal7: 'Docente Media',
    portal8: 'Apoderados Media',
    portal9: 'Estudiantes Básica',
    portal10: 'Estudiantes Media ',
    portal11: 'Vista Resumen',
    portal12: 'Vista Entrada ',
    portal13: 'Otros Básica',
    portal14: 'Otros Media',
    portal15: 'Mineduc Básica',
    portal16: 'Mineduc Media'

  };

  ACCIONES: any = {
    ac11: 11,
    ac12: 12,
    ac13: 13,
    ac14: 14,
    ac15: 15,
    ac16: 16,
    ac17: 17,
    ac18: 18,
    ac19: 19,
    ac40: 40,
    ac41: 41,
    ac42: 42,
    ac43: 43,
    ac44: 44,
    ac45: 45,
    ac46: 46,
    ac47: 47,
    ac48: 48,
    ac49: 49,
    ac50: 50,
    ac51: 51,
    ac52: 52,
    ac53: 53,
    ac54: 54,
    ac55: 55,
    ac56: 56,
    ac60: 60,
    ac61: 61,
    ac62: 62,
    ac63: 63,
    ac64: 64,
    ac65: 65,
    ac66: 66,
    ac67: 67,
    ac70: 70,
    ac71: 71,
    ac72: 72,
    ac73: 73,
    ac74: 74,
    ac75: 75,
    ac76: 76,
    ac77: 77,
    ac78: 78,
    ac79: 79,
    ac90: 90,
    ac91: 91,
    ac92: 92,
    ac93: 93,
    ac94: 94,
    ac97: 97,
    ac98: 98,
    ac99: 99,
    ac104: 104,
    ac108: 108,
    ac109: 109,
    ac110: 110,
    ac111: 111,
    ac112: 112,
    ac113: 113,
    ac114: 114,
    ac115: 115,
    ac116: 116,
    ac117: 117,
    ac118: 118,
    ac120: 120,
    ac121: 121,
    ac122: 122,
    ac123: 123,
    ac124: 124,
    ac125: 125,
    ac126: 126,
    ac127: 127,
    ac128: 128,
    ac130: 130,
    ac131: 131,
    ac132: 132,
    ac133: 133,
    ac134: 134,
    ac140: 140,
    ac141: 141,
    ac142: 142,
    ac144: 144,
    ac145: 145,
    ac146: 146,
    ac147: 147,
    ac761: 761,
    ac771: 771,
    ac772: 772,
    ac781: 781,
    ac782: 782,
    ac783: 783,
    ac784: 784,
    ac1001: 1001,
    ac1002: 1002,
    ac1003: 1003,
    ac1004: 1004,
    ac1005: 1005,
    ac1010: 1010,
    ac1011: 1011,
    ac1012: 1012,
    ac1013: 1013,
    ac1014: 1014,
    ac1015: 1015,
    ac1016: 1016,
    ac1020: 1020,
    ac1021: 1021,
    ac1022: 1022,
    ac1023: 1023,
    ac1024: 1024,
    ac1025: 1025,
    ac1030: 1030,
    ac1031: 1031
  };

  constructor(private http: HttpClient) {
  }

  registrar(rbd: number, accion: number, portal: number): Observable<any> {
    const userData: any = JSON.parse(localStorage.getItem('UserData'));
    const body = JSON.stringify({
      rbd, portal,
      accion: this.ACCIONES['ac' + accion],
      usuario: userData ? userData.rut : '',
      nombre: userData ? userData.nombre : '',
      duracion_sesion: '30'
    });

    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    const urlSrv = `${environment.API_DOMAINS}/tracking/registrar`;
    return this.http.post<any>(urlSrv, body, {headers}).pipe(
      map((result: any) => {
        return result;
      }));
  }

  obtenerNombrePorta(perfil: number, tipo: string): PortalModel {
    let nombrePortal = null;
    const tipoPortal = (tipo === 'basica' ? ' Básica' : ' Media');
    switch (perfil) {
      case 1:
        nombrePortal = new PortalModel({
          id : tipo === 'basica' ? 1 : 2,
          nombre : 'Portal Director' + tipoPortal,
          nombreLargo : 'Portal Director Educación' + tipoPortal,
          portal : 'director',
          nombreTipoPortal : 'Portal educación' + tipoPortal.toLowerCase()
        });
        break;
      case 2:
        nombrePortal = new PortalModel({
          id : tipo === 'basica' ? 3 : 4,
          nombre : 'Portal Sostenedor - Autoridad' + tipoPortal,
          nombreLargo : 'Portal Sostenedor - Autoridad Educación' + tipoPortal,
          portal : 'autoridad',
          nombreTipoPortal : 'Portal educación' + tipoPortal.toLowerCase()
        });
        break;
      case 3:
        nombrePortal = new PortalModel({
          id : tipo === 'basica' ? 5 : 7,
          nombre : 'Portal Docente' + tipoPortal,
          nombreLargo : 'Portal Docente Educación' + tipoPortal,
          portal : 'docente',
          nombreTipoPortal : 'Portal educación' + tipoPortal.toLowerCase()
        });
        break;
      case 4:
        nombrePortal = new PortalModel({
          id : tipo === 'basica' ? 6 : 8,
          nombre : 'Portal Apoderado' + tipoPortal,
          nombreLargo : 'Portal Apoderado Educación' + tipoPortal,
          portal : 'apoderado',
          nombreTipoPortal : 'Portal educación' + tipoPortal.toLowerCase()
        });
        break;
      case 5:
        nombrePortal = new PortalModel({
          id : tipo === 'basica' ? 9 : 10,
          nombre : 'Portal Estudiante' + tipoPortal,
          nombreLargo : 'Portal Estudiante Educación' + tipoPortal,
          portal : 'estudiante',
          nombreTipoPortal : 'Portal educación' + tipoPortal.toLowerCase()
        });
        break;
      case 6:
        nombrePortal = new PortalModel({
          id : tipo === 'basica' ? 11 : 12,
          nombre : 'Recursos para la Mejora',
          nombreLargo : 'Recursos para la Mejora',
          portal : 'recursos',
          nombreTipoPortal : 'Portal educación' + tipoPortal.toLowerCase()
        });
        break;
      case 7:
        nombrePortal = new PortalModel({
          id : tipo === 'basica' ? 13 : 14,
          nombre : 'Portal Otros' + tipoPortal,
          nombreLargo : 'Portal Otros' + tipoPortal,
          portal : 'otros',
          nombreTipoPortal : 'Portal educación' + tipoPortal.toLowerCase()
        });
        break;
      case 8:
        nombrePortal = new PortalModel({
          id : tipo === 'basica' ? 15 : 16,
          nombre : 'Portal Mineduc' + tipoPortal,
          nombreLargo : 'Portal Mineduc' + tipoPortal,
          portal : 'mineduc',
          nombreTipoPortal : 'Portal educación' + tipoPortal.toLowerCase()
        });
        break;
    }
    return nombrePortal;
  }

  /**
   * Metodo que se encarga de realizar el tracking de la aplicación.
   */
  public trackear(rbd: string, accion: number, portal: number) {
    let rbdTmp: number;
    if (isNaN(parseInt(rbd, null))) {
      rbdTmp = 0;
    } else {
      rbdTmp = parseInt(rbd, null);
    }
    this.registrar(rbdTmp, accion, this.PORTAL['portal' + portal]).subscribe(data => {
      console.log('tracking - ' + accion);
    }, error => {
      console.log(error);
    });
  }
}
